import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { ConfigValueItem } from 'constants/Constants'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal'
import InputField from 'components/inputs/InputField'
import { FormInputError } from 'IndexProductScreen'
import { toast } from 'bulma-toast'
import { API_URL } from 'constants/Constants'
import { SearchBar } from 'components/inputs/SearchBar'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { BaseModal } from 'components/modals/BaseModal'

{/* TODO: Can be pulled out into a common table header component */}
const ConfigTableHeaders = () => {

    return (
        <div className="columns is-vcentered" style={{ borderBottom: '1px solid rgb(228, 228, 228)', backgroundColor: '#fafafa' }}>
            <div className="column is-5">   
                <ColumnLabel label={`Label`} />
            </div>
            <div className="column is-5">
                <ColumnLabel label={`Value`} />
            </div>
            <div className="column is-1">
                {/* Leave space for actions button. */}
            </div>
        </div>
    )
}

type ConfigItemFormState = {
    label: string,
    value: string,
    shortDescription?: string,
}

const initialFormState: ConfigItemFormState = {
    label: '',
    value: ''
}

const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

interface FormErrorState {
    label: FormInputError,
    value: FormInputError,
    shortDescription: FormInputError,
}

const initialFormErrorState: FormErrorState = {
    label: initialFormInputErrorState,
    value: initialFormInputErrorState,
    shortDescription: initialFormInputErrorState,
}

const ManagePipeMaterialsScreen = ({

}:{


}) => {

    const { getAccessTokenSilently } = useAuth0()

    const [didPipeMaterialsUpdate, setDidPipeMaterialsUpdate] = useState<boolean>(false)
    const [isConfigRequestLoading, setIsConfigRequestLoading] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<ConfigValueItem>(null)
    const [isInEditState, setIsInEditState] = useState<boolean>(false)
    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [initialPipeMaterials, setInitialPipeMaterials] = useState<ConfigValueItem[]>(null)
    const [filterablePipeMaterials, setFilterablePipeMaterials] = useState<ConfigValueItem[]>(null)
    const [isPipeMaterialFormModalActive, setIsPipeMaterialFormModalActive] = useState<boolean>(false)
    const [configItemFormState, setConfigItemFormState] = useState<ConfigItemFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<FormErrorState>(initialFormErrorState)

    // TODO - replace with hook
    useEffect(() => {

        const getPipeMaterials = async () => {

            const accessToken: string = await getAccessTokenSilently()
    
            const response = await fetch(
            `${API_URL}/configuration/pipe-materials`
                , {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                }
            )
        
            const pipeMaterials: ConfigValueItem[] = await response.json()
            const nonDeletedAndSortedPipeMaterials: ConfigValueItem[] = 
            pipeMaterials
            .filter(_ => !_.isDeleted)
            .sort((a, b) => a.value.localeCompare(b.value))

            setInitialPipeMaterials(nonDeletedAndSortedPipeMaterials)
            setFilterablePipeMaterials(nonDeletedAndSortedPipeMaterials)
            setDidPipeMaterialsUpdate(false)
        }
      
          getPipeMaterials()
        //   .catch((error) => setSearchError(error))    
    }, [didPipeMaterialsUpdate])
    

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        setConfigItemFormState({
            ...configItemFormState,
            [e.target.name]: e.target.value
        })
    }

    const onCancelConfirmDeleteModal = () => {
        setIsConfirmDeleteModalActive(false)
        setItemToDelete(null)
    }

    const onDeletePipeMaterialItem = async (e: React.MouseEvent, itemToDelete: ConfigValueItem) => {
        e.preventDefault(); 
        setItemToDelete(itemToDelete)
        setIsConfirmDeleteModalActive(true) 
    }

    const onEditPipeMaterialItem = async (e: React.MouseEvent, itemToEdit: ConfigValueItem) => {
        e.preventDefault(); 

        setIsInEditState(true)

        setConfigItemFormState(itemToEdit)

        setIsPipeMaterialFormModalActive(true) 
    }

    const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterablePipeMaterials(initialPipeMaterials.filter(item => item.value.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }
    
    const onClearSearchQuery = (e: React.MouseEvent) => {
        setFilterablePipeMaterials(initialPipeMaterials)
    }
   
    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setConfigItemFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
        setIsPipeMaterialFormModalActive(false)
        setIsInEditState(false)
    }

    const deletePipeMaterial = async () => {

        setIsConfigRequestLoading(true)

        const accessToken: string = await getAccessTokenSilently()
        
        const response = await fetch(
            `${API_URL}/configuration/pipe-materials`
                , {
                method: 'DELETE',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(itemToDelete)
                }
        )

        if (response.status === 200) {
            toast({
                message: `Success, pipe material deleted.`,
                type: 'is-link',
                position: 'bottom-right',
              })

            setIsConfigRequestLoading(false)
            setDidPipeMaterialsUpdate(true)
            onCancelConfirmDeleteModal()
              
        } else {
            setIsConfigRequestLoading(false)

            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
              })
        }
     
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`

        const formErrors: FormErrorState = Object.entries(configItemFormState).reduce((acc, [key, value]) => {

            if (key === 'shortDescription') {
                return {
                    ...acc,
                    [key]: {
                        isError: false,
                        errorMessage: null,
                    }
                }
            }
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '',
                    errorMessage: value === null || value === '' ? mustNotBeEmptyError : null,
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false
        })
    }


    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const accessToken: string = await getAccessTokenSilently()
            
            setIsConfigRequestLoading(true)

            if (isInEditState) {

                const response = await fetch(
                    `${API_URL}/configuration/pipe-materials`
                        , {
                        method: 'PATCH',
                        redirect: 'follow',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": 'application/json'
                        },
                        body: JSON.stringify(configItemFormState)
                    }); 
    
                if (response.status === 200) {
                    toast({
                        message: `Success, pipe material updated.`,
                        type: 'is-link',
                        position: 'bottom-right',
                      })
    
                      setIsConfigRequestLoading(false)
                      onCancelModal(e)
                      setDidPipeMaterialsUpdate(true)
                      
                } else {
                    setIsConfigRequestLoading(false)
    
                    toast({
                        message: 'Oh no, something went wrong. Please try again.',
                        type: 'is-danger',
                        position: 'bottom-right',
                      })
                }

            } else {

                const response = await fetch(
                    `${API_URL}/configuration/pipe-materials`
                        , {
                        method: 'POST',
                        redirect: 'follow',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": 'application/json'
                        },
                        body: JSON.stringify(configItemFormState)
                    }); 
    
                if (response.status === 200) {
                    toast({
                        message: `Success, pipe material added.`,
                        type: 'is-link',
                        position: 'bottom-right',
                      })
    
                      setIsConfigRequestLoading(false)
                      onCancelModal(e)
                      setDidPipeMaterialsUpdate(true)
                      
                } else {
                    setIsConfigRequestLoading(false)
    
                    toast({
                        message: 'Oh no, something went wrong. Please try again.',
                        type: 'is-danger',
                        position: 'bottom-right',
                      })
                }

            }

        }
    }

    return(
        <div>  
            <BaseModal
                isModalActive={isPipeMaterialFormModalActive}
                modalTitle={`${isInEditState ? `Edit ` : `Add New `} Pipe Material`}
                onCancelModal={onCancelModal}>
                    <form>

                        <InputField
                            fieldLabel={`Value`}
                            onChange={onChangeInput}
                            fieldName={'value'}
                            value={configItemFormState.value}
                            errorValue={formErrorState.value}
                            isDisabled={isInEditState}
                            helpText={``} />  

                        <InputField
                            fieldLabel={`Label`}
                            onChange={onChangeInput}
                            fieldName={'label'}
                            value={configItemFormState.label}
                            errorValue={formErrorState.label}
                            helpText={``} />                                         

                        <div className="field is-grouped is-pulled-right pt-4">
                            <div className="control">
                                <button className={`button is-link ${isConfigRequestLoading ? `is-loading` : ``}`} onClick={onSubmitForm}>{isInEditState ? `Edit` : `Add`}</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={onCancelModal}>Cancel</button>
                            </div>
                        </div>

                    </form>
            </BaseModal>

        <ConfirmDeleteModal 
            isModalActive={isConfirmDeleteModalActive}
            isLoading={isConfigRequestLoading}
            onCancel={onCancelConfirmDeleteModal}
            onConfirmDelete={deletePipeMaterial} />

        <div className="columns mb-6">
            <div className="column is-10">
                    <h1 className="title is-3">Manage Pipe Materials</h1>
                    <h1 className="subtitle is-5 mb-3"></h1>
            </div>
            <div className="column">
                <button className="button is-link is-pulled-right" onClick={() => setIsPipeMaterialFormModalActive(true)}>Add <FontAwesomeIcon className="pl-2" icon={faPlus} /></button>
            </div>
        </div>

        {initialPipeMaterials && initialPipeMaterials.length > 0 
        ?
        <>
        <div className="columns mb-4 pb-4">
            <div className="column is-5 p-0">
                <SearchBar 
                    onChange={onChangeSearchQuery}
                    onClear={onClearSearchQuery} />
            </div>
        </div>

        {filterablePipeMaterials && filterablePipeMaterials.length > 0
        ?
        <div className="columns">
            <div className="column is-12">
                <div className="block">

                    <ConfigTableHeaders />

                    {filterablePipeMaterials.map((item, idx) => (
                        <div className={`columns is-vcentered pb-1 pt-1 ${item.isDeleted ? `has-background-white-bis` : ``}`} key={`code-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                            <div className="column is-5">
                                {item.label}
                            </div>
                            <div className="column is-5">
                                {item.value}
                            </div>       
                            <div className="column is-narrow">
                                {item.isDeleted
                                    ?
                                    <span className="tag is-danger is-light">Deleted</span>
                                    :
                                    <div className="dropdown is-hoverable is-pulled-right">
                                        <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                            <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                        </div>
                                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                            <div className="dropdown-content">
                                                <a href="#" className="dropdown-item" onClick={(e) => onDeletePipeMaterialItem(e, item)}>
                                                    <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                                </a>
                                                <hr className="navbar-divider"/>
                                                <a href="#" className="dropdown-item" onClick={(e) => onEditPipeMaterialItem(e, item)}>
                                                    <span><FontAwesomeIcon icon={faPencil} className="pr-2" /> Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
        :
        <div className="has-text-centered">
            <p>No pipe material(s) found.</p>
        </div>
        }
        </>
        :
        <PageLoadSpinner />
    }       
    </div>
    )
}

export { ManagePipeMaterialsScreen }