import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal'
import InputField from 'components/inputs/InputField'
import { FormInputError } from 'IndexProductScreen'
import { toast } from 'bulma-toast'
import { API_URL, ConfigValueItem } from 'constants/Constants'
import { SearchBar } from 'components/inputs/SearchBar'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { BaseModal } from 'components/modals/BaseModal'
import { usePipeOpenings } from 'hooks/usePipeOpenings'


export type PipeOpeningConfig = {
    id: string,
    insideDiameter: number,
	material: string,
	outsideDiameter: number, 
	openingSize: number,
    isDeleted: boolean
}

type PipeOpeningConfigFormState = {
    insideDiameter: number,
	material: string,
	outsideDiameter: number, 
	openingSize: number
}

const initialFormState: PipeOpeningConfigFormState = {
    insideDiameter: 0,
	material: '',
	outsideDiameter: 0, 
	openingSize: 0
}

const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

interface FormErrorState {
    insideDiameter: FormInputError,
    material: FormInputError,
    outsideDiameter: FormInputError,
    openingSize: FormInputError
}

const initialFormErrorState: FormErrorState = {
    insideDiameter: initialFormInputErrorState,
	material: initialFormInputErrorState,
	outsideDiameter: initialFormInputErrorState, 
	openingSize: initialFormInputErrorState
}

const ManagePipeOpeningsScreen = ({

}:{

}) => {


    const { getAccessTokenSilently } = useAuth0()

    const [pipeMaterials, setPipeMaterials] = useState<ConfigValueItem[]>(null)
    const [didPipeOpeningsUpdate, setDidPipeOpeningsUpdate] = useState<boolean>(false)
    const [isConfigRequestLoading, setIsConfigRequestLoading] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<PipeOpeningConfig>(null)
    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [initialPipeOpenings, setInitialPipeOpenings] = useState<PipeOpeningConfig[]>(null)
    const [filterablePipeOpenings, setFilterablePipeOpenings] = useState<PipeOpeningConfig[]>(null)
    const [isAddNewPipeOpeningModalActive, setIsAddNewPipeOpeningModalActive] = useState<boolean>(false)
    const [pipeOpeningConfigFormState, setPipeOpeningConfigFormState] = useState<PipeOpeningConfigFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<FormErrorState>(initialFormErrorState)
    const { pipeOpenings, getPipeOpenings } = usePipeOpenings()

    // TODO - clear this up and move hook.
    useEffect(() => {

        const getPipeMaterials = async () => {

            const accessToken: string = await getAccessTokenSilently()
    
            const response = await fetch(
            `${API_URL}/configuration/pipe-materials`
                , {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                }
            )
        
            const pipeMaterialsResp: ConfigValueItem[] = await response.json()
            const sortedPipeMaterials: ConfigValueItem[] = pipeMaterialsResp .sort((a, b) => a.value.localeCompare(b.value))
            
            setPipeMaterials(sortedPipeMaterials)
        }
      
          getPipeMaterials()
        //   .catch((error) => setSearchError(error))    
    }, [])
    

    // TODO - clear this up and move hook.
    useEffect(() => {  

        const getPipeOpenings = async () => {

            const accessToken: string = await getAccessTokenSilently()
    
              const response = await fetch(
                `${API_URL}/configuration/pipe-openings`
                    , {
                    method: 'GET',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    }
              )
         
              const pipeOpenings: PipeOpeningConfig[] = await response.json()
              const nonDeletedAndSortedPipeOpenings: PipeOpeningConfig[] = 
              pipeOpenings
                .filter(_ => !_.isDeleted)
                .sort((a, b) => a.material.localeCompare(b.material) || a.insideDiameter - b.insideDiameter)
              
              setInitialPipeOpenings(nonDeletedAndSortedPipeOpenings)
              setFilterablePipeOpenings(nonDeletedAndSortedPipeOpenings)
              setDidPipeOpeningsUpdate(false)
          }
      
          getPipeOpenings()
        //   .catch((error) => setSearchError(error))    
    }, [didPipeOpeningsUpdate])

    const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterablePipeOpenings(initialPipeOpenings.filter(item => item.material.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }
    
    const onClearSearchQuery = (e: React.MouseEvent) => {
        setFilterablePipeOpenings(initialPipeOpenings)
    }

    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setPipeOpeningConfigFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
        setIsAddNewPipeOpeningModalActive(false)
    }

    const onCancelConfirmDeleteModal = () => {
        setIsConfirmDeleteModalActive(false)
        setItemToDelete(null)
    }

    const onDeletePipeOpeningItem = async (e: React.MouseEvent, itemToDelete: PipeOpeningConfig) => {
        e.preventDefault(); 
        setItemToDelete(itemToDelete)
        setIsConfirmDeleteModalActive(true) 
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        setPipeOpeningConfigFormState({
            ...pipeOpeningConfigFormState,
            [e.target.name]: !isNaN(parseInt(e.target.value))  ? parseInt(e.target.value) : e.target.value
        })
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustBeGreaterThanZero: string = `Must be greater than zero`

        const formErrors: FormErrorState = Object.entries(pipeOpeningConfigFormState).reduce((acc, [key, value]) => {
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '' || (!isNaN(parseInt(value.toString())) && parseInt(value.toString()) <= 0),
                    errorMessage: value === null || value === '' ? mustNotBeEmptyError : (!isNaN(parseInt(value.toString())) && parseInt(value.toString()) <= 0)  ? mustBeGreaterThanZero : null,
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false
        })
    }


    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const accessToken: string = await getAccessTokenSilently()
            
            setIsConfigRequestLoading(true)

            const response = await fetch(
                `${API_URL}/configuration/pipe-openings`
                    , {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(pipeOpeningConfigFormState)
                }); 

            if (response.status === 200) {
                toast({
                    message: `Success, pipe opening added.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  setIsConfigRequestLoading(false)
                  onCancelModal(e)
                  setDidPipeOpeningsUpdate(true)
                  
            } else {
                setIsConfigRequestLoading(false)

                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }
        }
    }

    const deletePipeOpening = async () => {

        const accessToken: string = await getAccessTokenSilently()

        setIsConfigRequestLoading(true)
        
        const response = await fetch(
            `${API_URL}/configuration/pipe-openings`
                , {
                method: 'DELETE',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(itemToDelete)
                }
        )

        if (response.status === 200) {
            toast({
                message: `Success, pipe opening deleted.`,
                type: 'is-link',
                position: 'bottom-right',
              })

            setIsConfigRequestLoading(false)
            setDidPipeOpeningsUpdate(true)
            onCancelConfirmDeleteModal()
              
        } else {
            setIsConfigRequestLoading(false)

            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
              })
        }

    }
     
    return(
        <div>
            <BaseModal
                isModalActive={isAddNewPipeOpeningModalActive}
                modalTitle={`Add New Pipe Opening`}
                onCancelModal={onCancelModal}>
                    <form>
                        <div className="field">
                            <label className="label">Material</label> 
                            <div className={`select ${formErrorState.material && formErrorState.material.isError ? `is-danger`: ''}`}>
                                <select name="material" value={pipeOpeningConfigFormState.material} onChange={onChangeInput}>
                                    <option value="" disabled={true} >Select a material</option>
                                    {/* TODO - clear this up once we handle deletes properly */}
                                    {pipeMaterials && pipeMaterials.filter(_ => !_.isDeleted).map((pm, idx) => 
                                        <option key={`pipe-opening-material-${idx}`} value={pm.value}>{pm.label}</option>
                                    )}
                                </select>
                            </div>
                            {formErrorState.material && formErrorState.material.isError &&
                                <span className="help is-danger">{formErrorState.material.errorMessage}</span>
                            }
                        </div>

                        <InputField
                            fieldLabel={`Inside Diameter`}
                            onChange={onChangeInput}
                            fieldName={'insideDiameter'}
                            value={pipeOpeningConfigFormState.insideDiameter}
                            errorValue={formErrorState.insideDiameter}
                            helpText={``}
                            isNumber={true}
                            hasAddOn={`mm`}
                            isExpanded={true} />     
                                                                    
                        <InputField
                            fieldLabel={`Opening Size`}
                            onChange={onChangeInput}
                            fieldName={'openingSize'}
                            value={pipeOpeningConfigFormState.openingSize}
                            errorValue={formErrorState.openingSize}
                            helpText={``}
                            isNumber={true}
                            hasAddOn={`mm`}
                            isExpanded={true} />  

                        <InputField
                            fieldLabel={`Outside Diameter`}
                            onChange={onChangeInput}
                            fieldName={'outsideDiameter'}
                            value={pipeOpeningConfigFormState.outsideDiameter}
                            errorValue={formErrorState.outsideDiameter}
                            helpText={``}
                            isNumber={true}
                            hasAddOn={`mm`}
                            isExpanded={true} />                                         
                                    

                        <div className="field is-grouped is-pulled-right pt-4">
                            <div className="control">
                                <button className={`button is-link ${isConfigRequestLoading ? `is-loading` : ``}`} onClick={onSubmitForm}>Add</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={onCancelModal}>Cancel</button>
                            </div>
                        </div>

                    </form>
            </BaseModal>

        <ConfirmDeleteModal 
            isModalActive={isConfirmDeleteModalActive}
            isLoading={isConfigRequestLoading}
            onCancel={onCancelConfirmDeleteModal}
            onConfirmDelete={deletePipeOpening} />

        <div className="columns mb-6">
            <div className="column is-10">
                    <h1 className="title is-3">Manage Pipe Openings</h1>
                    <h1 className="subtitle is-5 mb-3"></h1>
            </div>
            <div className="column">
                <button className="button is-link is-pulled-right" onClick={() => setIsAddNewPipeOpeningModalActive(true)}>Add <FontAwesomeIcon className="pl-2" icon={faPlus} /></button>
            </div>
        </div>

        {pipeMaterials && initialPipeOpenings && initialPipeOpenings.length > 0 
        ?
        <>
        <div className="columns mb-4 pb-4">
            <div className="column is-5 p-0">
                <SearchBar 
                    onChange={onChangeSearchQuery}
                    onClear={onClearSearchQuery} />
            </div>
        </div>

        {filterablePipeOpenings && filterablePipeOpenings.length > 0
        ?
        <div className="columns">
            <div className="column is-12">
                <div className="block">

                {/* TODO: Can be pulled out into a common table header component */}
                <div className="columns is-vcentered" style={{ borderBottom: '1px solid rgb(228, 228, 228)', backgroundColor: '#fafafa' }}>
                    <div className="column is-5">
                        <ColumnLabel label={`Material`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Inside Diameter`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Opening Size`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Outside Diameter`} />
                    </div>
                    <div className="column is-1">
                        {/* Leave space for actions button. */}
                    </div>
                </div>

                    {filterablePipeOpenings
                    .sort((a, b) => {
                        if (a.material.toUpperCase() > b.material.toUpperCase()) return 1;
                        if (a.material.toUpperCase() < b.material.toUpperCase()) return -1;
                        return 0;
                    })
                    .map((item, idx) => (
                        <div className={`columns is-vcentered pb-1 pt-1 ${item.isDeleted ? `has-background-white-bis` : ``}`} key={`code-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                            <div className="column is-5">
                                {pipeMaterials.find(_ => _.value === item.material).label}
                            </div>   
                            <div className="column is-2">
                                {`${item.insideDiameter}mm`}
                            </div> 
                            <div className="column is-2">
                                {`${item.openingSize}mm`}
                            </div>
                            <div className="column is-2">
                                {`${item.outsideDiameter}mm`}
                            </div>
                            {item.isDeleted
                                    ?
                                    <span className="tag is-danger is-light">Deleted</span>
                                    :
                                    <div className="column is-1">
                                    <div className="dropdown is-hoverable is-pulled-right">
                                        <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                            <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                        </div>
                                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                            <div className="dropdown-content">
                                                <a href="#" className="dropdown-item" onClick={(e) => onDeletePipeOpeningItem(e, item)}>
                                                    <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }        
                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
        :
        <div className="has-text-centered">
            <p>No pipe opening(s) found.</p>
        </div>
        }
        </>
        :
        <PageLoadSpinner />
    }         
    </div>
    )
}

export { ManagePipeOpeningsScreen }