import React, { useState } from 'react'

import { ManageProductTypesScreen } from './configuration/ManageProductTypesScreen'
import { ManagePipeOpeningsScreen } from './configuration/ManagePipeOpeningsScreen'
import { ManagePipeMaterialsScreen } from './configuration/ManagePipeMaterialsScreen'
import { ManageFlowControlDevidesScreen } from './configuration/ManageFlowControlDevicesScreen'

enum ActiveConfigMenu {
    ProductTypeItem,
    PipeOpeningsItem,
    PipeMaterialsItem,
    FlowControlDeviceItem,
  }

const SettingsScreen = () => {

    const [activeMenuItem, setActiveMenuItem] = useState<ActiveConfigMenu>(ActiveConfigMenu.ProductTypeItem)


    const renderActiveMenuPage = () => {
        switch (activeMenuItem) {
            case ActiveConfigMenu.ProductTypeItem: 
                return( <ManageProductTypesScreen />)  
            case ActiveConfigMenu.PipeOpeningsItem: 
                return( <ManagePipeOpeningsScreen />)   
            case ActiveConfigMenu.PipeMaterialsItem: 
                return( <ManagePipeMaterialsScreen />)     
            case ActiveConfigMenu.FlowControlDeviceItem: 
                return( <ManageFlowControlDevidesScreen />)     
        }
    }

    return(
        <div className="container is-fullheight">

            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{borderRadius: 6}}>

                    <div className="columns">
                        <div className="column is-3 p-5" style={{borderRight: 'rgb(228, 228, 228) 1px solid'}}>

                            <aside className="menu">
                                <p className="menu-label">
                                    Configuration
                                </p>
                                <ul className="menu-list">
                                    <li><a className={`${activeMenuItem === ActiveConfigMenu.ProductTypeItem ? `is-active` : ''}`} onClick={() => setActiveMenuItem(ActiveConfigMenu.ProductTypeItem)}>Product Types</a></li>
                                    <li><a className={`${activeMenuItem === ActiveConfigMenu.PipeOpeningsItem ? `is-active` : ''}`} onClick={() => setActiveMenuItem(ActiveConfigMenu.PipeOpeningsItem)}>Pipe Openings</a></li>
                                    <li><a className={`${activeMenuItem === ActiveConfigMenu.FlowControlDeviceItem ? `is-active` : ''}`} onClick={() => setActiveMenuItem(ActiveConfigMenu.FlowControlDeviceItem)}>Flow Control Devices</a></li>
                                    <li><a className={`${activeMenuItem === ActiveConfigMenu.PipeMaterialsItem ? `is-active` : ''}`} onClick={() => setActiveMenuItem(ActiveConfigMenu.PipeMaterialsItem)}>Pipe Materials</a></li>
                                </ul>
                            </aside>
                            
                        </div>
                        <div className="column p-6">
                            {renderActiveMenuPage()}
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export { SettingsScreen }