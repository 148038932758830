import { faArrowDown, faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MetadataStatusType, ProductSearchResult } from 'SearchScreen'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import React from 'react'
import { APPROVED, EDITS_REQUESTED, PENDING_REVIEW } from './ManageProductsScreen'
import { prettyDisplayValue } from 'constants/Constants'
import { User } from 'ManageUsersScreen'
import { BaseModal } from 'components/modals/BaseModal'

export const StatusTag = ({status, className = ``} : {status: MetadataStatusType, className?: string }) => (
    <span
     className={
      `tag
      ${className}  
      ${status === PENDING_REVIEW 
      ? 'is-info'
      : status === EDITS_REQUESTED 
      ? 'is-warning'
      : 'is-primary'
    }`}>
      {prettyDisplayValue(status)}
  </span>
  )

const VersionHistoryModal = ({
    productVersions,
    isModalActive,
    isLoading,
    loggedInUser,
    setIsVersionHistoryModalActive,
}:{
    productVersions: ProductSearchResult[],
    isModalActive: boolean,
    isLoading: boolean,
    loggedInUser: User,
    setIsVersionHistoryModalActive: (isActive: boolean) => void
}) => {

    return (
        <BaseModal 
            isModalActive={isModalActive}
            modalTitle={`Version History`}
            onCancelModal={() => setIsVersionHistoryModalActive(false)}>

                <div className="columns is-vcentered p-4">
                    <div className="column">
                        {isLoading
                        ?
                        <PageLoadSpinner />
                        :
                        productVersions && productVersions.length > 0
                        ?
                        <div className="columns">
                            <div className="column">
                            <div className="timeline">
                                {productVersions.map((pv) => {

                                return (
                                    <div key={`${pv.objectID}-${pv.metadata.version}`}>
                                    <header className="timeline-header">
                                    {pv.metadata.version === 0 && pv.metadata.status === PENDING_REVIEW
                                        ?
                                        <span className={`tag is-primary`}>{`Created`}</span>
                                        :
                                        <StatusTag status={pv.metadata.status} />
                                        }
                                    </header>
                                    

                                    <div className="timeline-item" key={`${pv.objectID}-${pv.metadata.version}`}>
                                    <div className="timeline-marker is-icon">
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    </div>
                                    <div className="timeline-content">
                                        <p className="heading">
                                        {pv.metadata.version === 0
                                        ?
                                        pv.metadata.created && <span>{pv.metadata.created.timestamp}</span>
                                        :
                                        pv.metadata.status === PENDING_REVIEW
                                        ?
                                        pv.metadata.lastEdited && <span>{pv.metadata.lastEdited.timestamp}</span>
                                        :
                                        pv.metadata.status === EDITS_REQUESTED || pv.metadata.status === APPROVED 
                                        ?
                                        pv.metadata.lastModerated && <span>{pv.metadata.lastModerated.timestamp}</span>
                                        :
                                        ''
                                        }
                                        </p>
                                        
                                        <div className="columns"> 
                                        <div className="column">  

                                            {pv.metadata.version === 0
                                            ?
                                            pv.metadata.created &&
                                            <div className="columns is-vcentered">
                                            <div className="column is-narrow p-0">
                                                <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                            </div>
                                            <div className="column is-narrow">
                                                {pv.metadata.created && <span>{`${pv.metadata.created.byFullName} ${pv.metadata.created.byFullName === loggedInUser.name ? `(You)` : ``}`}</span>}
                                            </div>
                                            </div>
                                            :
                                            pv.metadata.status === PENDING_REVIEW
                                            ?
                                            <div className="columns is-vcentered">
                                            <div className="column is-narrow p-0">
                                                <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                            </div>
                                            <div className="column is-narrow">
                                                {pv.metadata.lastEdited && <span>{`${pv.metadata.lastEdited.byFullName} ${pv.metadata.lastEdited && pv.metadata.lastEdited.byFullName === loggedInUser.name ? `(You)` : ``} made edits`}</span>}
                                            </div>
                                            </div>
                                            :
                                            pv.metadata.status === EDITS_REQUESTED 
                                            ?
                                            <div className="columns is-vcentered">
                                            <div className="column is-narrow p-0">
                                                <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                            </div>
                                            <div className="column is-narrow">
                                                {pv.metadata.lastModerated && <span>{`${pv.metadata.lastModerated.byFullName} ${pv.metadata.lastModerated.byFullName === loggedInUser.name ? `(You)` : ``} reviewed and requested edits`}</span>}
                                            </div>
                                            </div>
                                            :
                                            pv.metadata.status === APPROVED 
                                            ?
                                            <div className="columns is-vcentered">
                                            <div className="column is-narrow p-0">
                                                <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                            </div>
                                            <div className="column is-narrow">
                                                {pv.metadata.lastModerated && <span>{`${pv.metadata.lastModerated.byFullName} ${pv.metadata.lastModerated.byFullName === loggedInUser.name ? `(You)` : ``} reviewed and approved`}</span>}
                                            </div>
                                            </div>
                                            :
                                            ''
                                            }

                                        </div> 
                                        </div>

                                        <div className="columns"> 
                                        <div className="column pt-0">  
                                            {pv.metadata.status === PENDING_REVIEW
                                            ?
                                            pv.metadata.lastEdited && pv.metadata.lastEdited.comment && 
                                            <article className="message is-light">
                                                <div className="message-body">
                                                <p className="pl-3">"{pv.metadata.lastEdited.comment}"</p>
                                                </div>
                                            </article>
                                            :
                                            pv.metadata.status === EDITS_REQUESTED || pv.metadata.status === APPROVED 
                                            ?
                                            pv.metadata.lastModerated && pv.metadata.lastModerated.comment && 
                                            <article className="message is-light">
                                                <div className="message-body">
                                                <p className="pl-3">"{pv.metadata.lastModerated.comment}"</p>
                                                </div>
                                            </article>
                                            :
                                            ''
                                            }
                                        </div> 
                                        </div>

                                    </div>
                                    </div>
                                </div>
                                )}
                                )}
                                <div className="timeline-header">
                                    <span className="tag is-primary">Current</span>
                                </div>
                            </div>
                            </div>
                            </div>
                        :
                        <p>There is no version history for this product.</p>
                        }
                    </div>
                </div>
        </BaseModal>
    )
}

export { VersionHistoryModal }