import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'
import { IDrawingSearchResponse } from 'models/SearchResponse'
import { SearchFilters } from './useSearchQueryAndFilters'

export const useDrawings = () => {

    const [drawings, setDrawingSearchResponse] = useState<IDrawingSearchResponse>()
    const { getAccessTokenSilently  } = useAuth0()

    const searchDrawingsApi = async (query: string, filters?: SearchFilters, page?: number): Promise<Response> => {

        const pageParam: string = page ? `&page=${page}` : ``
    
        const accessToken: string = await getAccessTokenSilently()
    
        if (filters) {
          const typeFilters: string = filters && filters.type && filters.type.appliedFilters.length > 0  ? `&type=${filters.type.appliedFilters.join(',')}` : ''
          const categoryFilters: string = filters && filters.category && filters.category.appliedFilters.length > 0  ? `&category=${filters.category.appliedFilters.join(',')}` : ''
    
          return fetch(
            `${API_URL}/drawings/search?q=${query}${pageParam}${typeFilters}${categoryFilters}`
              , {
                method: 'GET',
                redirect: 'follow',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": 'application/json'
              },
              }); 
        } else {
          return fetch(
            `${API_URL}/drawings/search?q=${query}${pageParam}`
              , {
                method: 'GET',
                redirect: 'follow',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": 'application/json'
              },
              }); 
        }
      }

      const searchDrawings = async (query: string, filters?: SearchFilters, page?: number) => {
 
        const response = await searchDrawingsApi(query, filters, page)
        const searchResp = await response.json()
        setDrawingSearchResponse(searchResp)
      }

    return {
        drawings,
        searchDrawings,
        searchDrawingsApi
    }
}