import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'

export interface FlowControlDeviceConfig {
    id: string,
    type: string,
    size: number,
	manufacturer?: string,
}

export interface IUsePipeMaterials {
    flowControlDevices: FlowControlDeviceConfig[],
    getPipeMaterials: () => void
}

export const useFlowControlDevices = () => {

    const [flowControlDevices, setFlowControlDevices] = useState<FlowControlDeviceConfig[]>(null)
    const { getAccessTokenSilently  } = useAuth0()

    const getFlowControlDevices = async () => {

        const accessToken: string = await getAccessTokenSilently()

        const response = await fetch(
        `${API_URL}/configuration/flow-control-devices`
            , {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            }
        )
    
        const flowControlDevicesResp: FlowControlDeviceConfig[] = await response.json()
        const sortedFlowControlDevices = flowControlDevicesResp.sort((a,b) => a.type.localeCompare(b.type))
        setFlowControlDevices(sortedFlowControlDevices)
    }

    return {
        flowControlDevices,
        getFlowControlDevices
    }
}