import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'
import { DesignDocumentResponse } from 'models/SearchResult'

export interface Asset {
    fileName: string,
    fileExtension: string,
    filePath?: string,
    sourceUrl?: string,
    isUploaded?: boolean,
    isLoading: boolean,
    error?: string
}

interface AssetInfo {
    assetPath: string, 
    assetFileName: string,
    assetFileExtension: string,
}

export interface DesignDocument {
    category: string,
    asset: AssetInfo
}

interface Category {
    category: string, 
    error: string
}

export interface DesignDocumentFormState {
    category: Category,
    asset: Asset
}

const initialAssetState: Asset = {
    fileName: undefined,
    fileExtension: undefined,
    filePath: undefined,
    sourceUrl: undefined,
    isUploaded: false,
    isLoading: false,
    error: undefined
}


const initialDesignDocumentState: DesignDocumentFormState = {
    category: {
        category: '',
        error: null
    },
    asset: initialAssetState
}

const initialDesignDocumentsState: DesignDocumentFormState[] = [ initialDesignDocumentState ]


export interface IUseDesignDocumentsForm {
    formState: DesignDocumentFormState[],
    loadFormState: (designDocumentsToLoad: DesignDocumentResponse[]) => void,
    resetFormState: () => void,
    validateForm: () => boolean,
    onAddDesignDocument: (e: React.MouseEvent) => void,
    onDeleteDesignDocument: (e: React.MouseEvent, indexToRemove: number) => void,
    onChangeDesignDocumentCategory: (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number) => void,
    onChangeFileInput: (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => void,
    removeAsset: (indexToRemove: number) => void,
}

export const useDesignDocumentsForm = (): IUseDesignDocumentsForm => {

    const loadFormState = (designDocumentsToLoad: DesignDocumentResponse[]) => {
        setFormState([
            ...designDocumentsToLoad.map((doc) => ({
                category: {
                    category: doc.category, 
                    error: null,
                },
                asset: {
                    fileName: doc.asset.assetFileName, 
                    fileExtension: doc.asset.assetFileExtension, 
                    filePath: doc.asset.assetPath,
                    sourceUrl: doc.asset.assetUrl,
                    isLoading: false,
                    isUploaded: true,
                }
            }))
        ])
    }

    const [formState, setFormState] = useState<DesignDocumentFormState[]>(initialDesignDocumentsState)
    const { getAccessTokenSilently  } = useAuth0()

    const resetFormState = () => setFormState(initialDesignDocumentsState)

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustSelectADrawingError: string = `You must select a drawing to upload.`

        const designDocumentStateWithErrors: DesignDocumentFormState[] = formState.map((doc) => ({
            ...doc,
            category: {
                ...doc.category,
                error: doc.category.category && doc.category.category !== '' ? null : mustNotBeEmptyError
            },
            asset: {
                ...doc.asset,
                error: doc.asset.filePath === null || doc.asset.filePath === undefined ? mustSelectADrawingError : null
            }
        }))

        setFormState(designDocumentStateWithErrors)

        return designDocumentStateWithErrors.every(_ => _.asset.error === null && _.category)
    }

    const onAddDesignDocument = (e: React.MouseEvent) => {
        e.preventDefault()
    
        setFormState (
            [...formState, initialDesignDocumentState]
        )
    }

    const onDeleteDesignDocument = (e: React.MouseEvent, indexToRemove: number) => {
        e.preventDefault()
    
        setFormState(
            formState.filter((_, idx) => idx != indexToRemove)
        )
    }

    const onChangeDesignDocumentCategory = (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number) => {
    
        setFormState(
            formState.map((doc, idx) => {
                if (idx !== indexToUpdate) return doc
                return {
                    ...doc, 
                    category: {
                        ...doc.category,
                        category: e.target.value
                    } 
                }
            })
        )
    }

        // TODO: fix
        const removeAsset = (indexToRemove: number) => setFormState(
            formState.map((doc, idx) => {
                if (idx !== indexToRemove) return doc
                return {
                    ...doc,
                    asset: initialAssetState
                }
            })
        )
    
        const onChangeFileInput = async (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => {
            if (e.target.files.length > 0) {
                const file: File = Array.from(e.target.files)[0]
                const fileName: string = file.name.substring(0, file.name.lastIndexOf('.'))
                const fileExtension: string = file.name.substring(file.name.lastIndexOf('.') + 1)
    
                setFormState(
                    formState.map((doc, idx) => {
                        if (idx !== indexToUpdate) return doc
                        return {
                            ...doc,
                            asset: {
                                ...doc.asset,
                                fileName: fileName, 
                                fileExtension: fileExtension, 
                                isLoading: true 
                            }
                        }
                    })
                )
    
                try {
    
                    const accessToken: string = await getAccessTokenSilently()
    
                    const getPresignedUrlResponse = await fetch(
                        `${API_URL}/get-presigned-url`
                            , {
                            method: 'POST',
                            redirect: 'follow',
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                "Content-Type": 'application/json'
                            },
                            body: JSON.stringify({
                                fileName: fileName,
                                fileExtension: fileExtension
                            })
                        }); 
        
                    const getPresignedUrlResponseBody = await getPresignedUrlResponse.json()
        
                    const getUploadResponse = await fetch(getPresignedUrlResponseBody.uploadUrl, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': file.type,
                        },
                        body: file
                    })
        
                    if (getUploadResponse.ok) {
    
                        setFormState(
                            formState.map((doc, idx) => {
                                if (idx !== indexToUpdate) return doc
                                return {
                                    ...doc,
                                    asset: {
                                        ...doc.asset,
                                        fileName: fileName, 
                                        fileExtension: fileExtension, 
                                        filePath: getPresignedUrlResponseBody.assetPath,
                                        sourceUrl: getPresignedUrlResponseBody.retrievalUrl,
                                        isLoading: false,
                                        isUploaded: true,
                                    }
                                }
                            })
                        )

                        // required to reset the file input so that the event is fired of file name is the same.
                        e.target.value = null;
    
                    } else {
    
                        setFormState(
                            formState.map((doc, idx) => {
                                if (idx !== indexToUpdate) return doc
                                return {
                                    ...doc,
                                    asset: {
                                        ...doc.asset,
                                        fileName: fileName, 
                                        fileExtension: fileExtension, 
                                        isLoading: true, 
                                        isUploaded: false, 
                                        error: `File upload failed. Please try again.`
                                    }
                                }
                            })
                        )
                
                    }
    
                } catch (error) {
    
                    setFormState(
                        formState.map((doc, idx) => {
                            if (idx !== indexToUpdate) return doc
                            return {
                                ...doc,
                                asset: {
                                    ...doc.asset,
                                    fileName: fileName, 
                                    fileExtension: fileExtension, 
                                    isLoading: false,
                                    isUploaded: false, 
                                    error: `File upload failed. Please try again.`
                                }
                            }
                        })
                    )
                }
            }
        }



    return {
        formState,
        loadFormState,
        resetFormState,
        validateForm,
        onAddDesignDocument,
        onDeleteDesignDocument,
        onChangeDesignDocumentCategory,
        onChangeFileInput,
        removeAsset
    }
}