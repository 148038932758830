import React, { useEffect, useState } from 'react'

import ColumnLabel from 'components/headers/table/ColumnLabel'
import InputField from 'components/inputs/InputField'
import { FormInputError } from 'IndexProductScreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'bulma-toast'
import { API_URL, merchants } from 'constants/Constants'
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { BaseModal } from 'components/modals/BaseModal'
import { isMerchant } from 'models/User'

interface CompanyInfo { id: string, name: string }

type Merchant = CompanyInfo
type Organisation = CompanyInfo

export interface User {
    user_id: string,
    firstName: string,
    surname: string,
    name: string,
    email: string,
    lastLogin: string,
    organisation: Organisation,
    merchant?: Merchant,
    role: string,
    userType: string,
}

interface IGetUsersSearchResponse {
    pages: number,
    page: 0,
    pageSize: number,
    noOfResults: number,
    results: User[]
}

const UserTableHeaders = () => {

    return (
        <div className="columns is-vcentered" style={{backgroundColor: '#fafafa'}}>
        <div className="column is-3">   
            <ColumnLabel label={`User Details`} />
        </div>
        <div className="column is-2">
            <ColumnLabel label={`Company`} />
        </div>
        <div className="column is-2">
            <ColumnLabel label={`Role`} />
        </div>
        <div className="column is-2">
            <ColumnLabel label={`User Type`} />
        </div>
        <div className="column is-2">
            <ColumnLabel label={`Last Login`} />
        </div>
        <div className="column is-1">
            {/* Leave space for actions button. */}
        </div>
    </div>
    )
}

interface AddUserFormState {
    firstName: string,
    surname: string,
    email: string,
    role: string,
    merchant: Merchant,
    userType: string
}

interface CreateUserRequest {
    firstName: string,
    surname: string,
    email: string,
    role: string,
    userType: string,
    merchant?: Merchant
}

const initialAddUserFormState: AddUserFormState = {
    firstName: '',
    surname: '',
    email: '',
    role: '',
    merchant: {
        id: '',
        name: ''
    },
    userType: 'employee'
}

interface AddUserFormErrorState {
    firstName: FormInputError,
    surname: FormInputError,
    email: FormInputError,
    role: FormInputError,
    merchant: FormInputError,
    userType: FormInputError
}

const initialFormError: FormInputError = {
    isError: false,
    errorMessage: null
}

const initialAddUserFormErrorState: AddUserFormErrorState = {
    firstName: initialFormError,
    surname: initialFormError,
    email: initialFormError,
    role: initialFormError,
    merchant: initialFormError,
    userType: initialFormError,
}

const ManageUsersScreen = ({ loggedInUser }: { loggedInUser: User }) => {

    const { getAccessTokenSilently } = useAuth0()

    const [userIdToDelete, setUserIdToDelete] = useState<string>(null)
    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [userSearchResponse, setUserSearchResponse] = useState<IGetUsersSearchResponse>(null)
    const [didUsersUpdate, setDidUsersUpdate] = useState(false)
    const [isUserRequestLoading, setIsUserRequestLoading] = useState(false)
    const [isRoleSelectInputReadOnly, setIsRoleSelectInputReadOnly] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)
    const [addUserFormState, setAddUserFormState] = useState<AddUserFormState>(initialAddUserFormState)
    const [addUserFormErrorState, setAddUserFormErrorState] = useState<AddUserFormErrorState>(initialAddUserFormErrorState)

    const getUsers = async (page?: number) => {

        const accessToken: string = await getAccessTokenSilently()

        const pageParam: string = page ? `?page=${page}` : ``

        const getUsersResponse: Promise<Response> = fetch(`${API_URL}/users${pageParam}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
        })
    
        const response: Response = await getUsersResponse
        const searchResponse: IGetUsersSearchResponse = await response.json()
    
        setUserSearchResponse(searchResponse)
        setDidUsersUpdate(false)
    }

    useEffect(() => {
        getUsers()
            
    }, [didUsersUpdate])

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        setAddUserFormState({
            ...addUserFormState,
            [e.target.name]: e.target.value
        })
    }

    const onSelectMerchant = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        console.log(e.target.value)
        const merchant = merchants.find(_ => _.id === e.target.value)

        setAddUserFormState({
            ...addUserFormState,
            merchant: {
                id: merchant.id,
                name: merchant.name
            }
        })
    }

    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setAddUserFormState(initialAddUserFormState)
        setAddUserFormErrorState(initialAddUserFormErrorState)
        setIsModalActive(false)
    }

    const validateForm = (): boolean => {

        console.log(addUserFormState)

        const mustNotBeEmptyError: string = `Must not be empty`

        const formErrors: AddUserFormErrorState = Object.entries(addUserFormState).reduce((acc, [key, value]) => {

            if(key === 'merchant') {

                const merchant: Merchant = value

                return {
                    ...acc,
                    [key]: {
                        isError: addUserFormState.userType === 'merchant' && 
                        (merchant.id === null || merchant.id === '') && 
                        (merchant.name === null || merchant.name === ''),
                        errorMessage: mustNotBeEmptyError
                    }
                } 
            }
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '',
                    errorMessage: mustNotBeEmptyError
                }
            }
        }, initialAddUserFormErrorState)

        setAddUserFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false
        })
    }


    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const addUserRequest: CreateUserRequest = {
                firstName: addUserFormState.firstName,
                surname: addUserFormState.surname,
                email: addUserFormState.email,
                role: addUserFormState.role,
                ...(addUserFormState.userType === 'merchant' && {merchant:  addUserFormState.merchant}),
                userType: addUserFormState.userType,
            }

            const accessToken: string = await getAccessTokenSilently()
            
            setIsUserRequestLoading(true)

            const response = await fetch(
                `${API_URL}/users`
                    , {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(addUserRequest)
                }); 

            if (response.status === 201) {
                toast({
                    message: `Success, user created.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  setIsUserRequestLoading(false)
                  onCancelModal(e)
                  setDidUsersUpdate(true)
                  
            } else {
                setIsUserRequestLoading(false)

                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }
        }
    }

    const onUpdateUserRole = async (e: React.ChangeEvent<HTMLSelectElement>, userId: string) => {

        const newRole: string = e.target.value

        const accessToken: string = await getAccessTokenSilently()

        const response = await fetch(
            `${API_URL}/users/${encodeURI(userId)}`
                , {
                method: 'PATCH',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({ role: newRole })
            }) 

            if (response.status === 200) {

                setDidUsersUpdate(true)

                toast({
                    message: `Success, role updated.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

            } else {

                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }

    }

    const onChangeUserType = (e: React.ChangeEvent<HTMLInputElement>) => {

        const userType: string = e.target.value
        const isMerchant: boolean = userType === 'merchant'
        const isEmployee: boolean = userType === 'employee'
    
        setAddUserFormState({
            ...addUserFormState,
            userType: userType,
            ...(isMerchant && {role: 'STANDARD'}),
            ...(isEmployee && {merchant: {id: '', name: ''}})
        })

        isMerchant ? setIsRoleSelectInputReadOnly(true) : setIsRoleSelectInputReadOnly(false)
    }

    const onCancelConfirmDeleteModal = () => {
        setIsConfirmDeleteModalActive(false)
        setUserIdToDelete(null)
    }

    const onDeleteUser = async (e: React.MouseEvent, userId: string) => {
        e.preventDefault();

        setIsConfirmDeleteModalActive(true)
        setUserIdToDelete(userId)
    }


    const deleteUser = async () => { 
        
        const accessToken: string = await getAccessTokenSilently()

        setIsUserRequestLoading(true)

        const response = await fetch(
            `${API_URL}/users/${encodeURI(userIdToDelete)}`
                , {
                method: 'DELETE',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
            }) 

            if (response.status === 204) {

                setDidUsersUpdate(true)
                setIsUserRequestLoading(false)
                onCancelConfirmDeleteModal()

                toast({
                    message: `Success, user deleted.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

            } else {
                setIsUserRequestLoading(false)

                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }

    }

    const prettyDate = (dateVal: string) => {
        var options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var date: Date = new Date(dateVal);
        return date.toLocaleDateString('en-GB', options)
    } 

    const onClickPage = (pageNo: number) => {
        getUsers(pageNo)
    }


    return(
        <div className="container is-fullheight">

            <ConfirmDeleteModal 
                isModalActive={isConfirmDeleteModalActive}
                isLoading={isUserRequestLoading}
                onCancel={onCancelConfirmDeleteModal}
                onConfirmDelete={deleteUser} />

            <BaseModal
                isModalActive={isModalActive}
                modalTitle={`Add User`}
                onCancelModal={onCancelModal}>
                    <form>

                        <InputField
                            fieldLabel={`First Name`}
                            onChange={onChangeInput}
                            fieldName={'firstName'}
                            value={addUserFormState.firstName}
                            errorValue={addUserFormErrorState.firstName}
                            helpText={`First name of the user`} />

                        <InputField
                            fieldLabel={`Surname`}
                            onChange={onChangeInput}
                            fieldName={'surname'}
                            value={addUserFormState.surname}
                            errorValue={addUserFormErrorState.surname}
                            helpText={`Surname of the user`} />

                        <InputField
                            fieldLabel={`Email`}
                            onChange={onChangeInput}
                            fieldName={'email'}
                            value={addUserFormState.email}
                            errorValue={addUserFormErrorState.email}
                            helpText={`Email of the user`} />

                        <div className="field pb-1">
                            <label className="label">User Type</label> 
                            <input onChange={onChangeUserType} className="is-checkradio" id="employeeUserType" type="radio" value="employee" name="userType" checked={addUserFormState.userType === 'employee'} />
                            <label htmlFor="employeeUserType">Employee</label>
                            <input onChange={onChangeUserType} className="is-checkradio" id="merchantUserType" type="radio" value="merchant" name="userType" checked={addUserFormState.userType === 'merchant'} />
                            <label htmlFor="merchantUserType">Merchant</label>
                        </div>

                        {addUserFormState.userType === 'merchant' && 
                            <div className="field pb-1">
                                <label className="label">Merchant</label> 
                                <div className="select">
                                    <select name="merchant" value={addUserFormState.merchant.id} onChange={onSelectMerchant}>
                                        <option value="" disabled={true} >Select a merchant</option>
                                        {merchants.map((merchant, i) => (
                                            <option value={merchant.id} key={`merchant-${i}`}>{merchant.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {addUserFormErrorState && addUserFormErrorState.merchant.isError && 
                                    <span className="help is-danger">{addUserFormErrorState.merchant.errorMessage}</span>
                                }
                            </div>
                        }

                        <div className="field pb-3">
                            <label className="label">Role</label> 
                            <div className="select">
                                <select name="role" value={addUserFormState.role} onChange={onChangeInput} disabled={isRoleSelectInputReadOnly}>
                                    <option value="" disabled={true} >Select a role</option>
                                    <option value="ADMIN">Admin</option>
                                    <option value="STANDARD">Standard</option>
                                </select>
                            </div>
                            {addUserFormErrorState && addUserFormErrorState.role.isError && 
                                <span className="help is-danger pb-1">{addUserFormErrorState.role.errorMessage}</span>
                            }
                            <p className="help">An <span className="has-text-weight-semibold">ADMIN</span> role provides elevate priviledges to be able to add/edit products and manage users and settings. </p>
                        </div>

                        <div className="field is-grouped is-pulled-right">
                            <div className="control">
                                <button className={`button is-link ${isUserRequestLoading ? `is-loading` : ``}`} onClick={onSubmitForm}>Add User</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={(e) => onCancelModal(e)}>Cancel</button>
                            </div>
                        </div>

                    </form>
            </BaseModal>


            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{padding: '5rem', borderRadius: 6}}>

                        <div className="columns">
                            <div className="column">
                                <h1 className="title is-3 mb-6">Manage Users</h1>
                            </div>
                            <div className="column">
                                <button className="button is-link is-pulled-right" onClick={() => setIsModalActive(true)}>Add User <FontAwesomeIcon className="pl-2" icon={faUserPlus} /></button>
                            </div>
                        </div>
                    

                        {userSearchResponse && userSearchResponse.results.length > 0 
                        ?
                            <div className="block">

                                <UserTableHeaders />

                                {userSearchResponse.results.map((user, idx) => (
                                    <div className="columns is-vcentered" key={`user-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                                        <div className="column is-3">
                                            <div className="columns is-vcentered">
                                                <div className="column is-narrow">
                                                    <div className="avatar-circle">
                                                        <span className="initials">{(user.firstName && user.firstName.substring(0,1).toUpperCase()) + (user.surname && user.surname.substring(0,1).toUpperCase())}</span>
                                                    </div>
                                                </div>
                                                <div className="column is-narrow">
                                                    <div className="has-text-weight-bold">{user.name} {user.email === loggedInUser.email ? '(You)' : ``}</div>
                                                    <div className="has-text-weight-light">{user.email}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-2">
                                            {isMerchant(user) ? user.merchant && user.merchant.name : user.organisation && user.organisation.name}
                                        </div>        

                                        <div className="column is-2">
                                            <div className="field">
                                                <div className="select">
                                                    <select className={``} name="role" value={user.role} onChange={(e) => onUpdateUserRole(e, user.user_id)} disabled={isMerchant(user)}>
                                                        <option value="" disabled={true} >Select a role</option>
                                                        <option value="ADMIN">Admin</option>
                                                        <option value="STANDARD">Standard</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-2">
                                            {isMerchant(user) ? `Merchant` : `Employee`}
                                        </div>
                                        <div className="column is-2">
                                            {user.lastLogin ? prettyDate(user.lastLogin) : `never`}
                                        </div>
                                        <div className="column is-narrow">
                                        <div className="dropdown is-hoverable is-pulled-right">
                                                <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                                    <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                                </div>
                                                <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                                    <div className="dropdown-content">
                                                        <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => onDeleteUser(e, user.user_id)}>
                                                            <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete User</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <nav className="pagination is-centered mt-6" role="navigation" aria-label="pagination">
                                    <a onClick={() => onClickPage(userSearchResponse.page -1)} className={`pagination-previous ${userSearchResponse.page === 0 ? `is-disabled` : ``}`}>Previous</a>
                                    <a onClick={() => onClickPage(userSearchResponse.page +1)} className={`pagination-next ${userSearchResponse.page === userSearchResponse.pages - 1 ? `is-disabled` : ``}`}>Next page</a>
                                    <ul className="pagination-list">
                                        {Array.from(Array(userSearchResponse.pages).keys()).map((pageNo: number) => (
                                        <li onClick={() => onClickPage(pageNo)} key={`page-num-${pageNo}`}><a className={`pagination-link ${userSearchResponse.page === pageNo ? `is-current` : ``}`} aria-label={`Goto page ${pageNo}`}>{pageNo + 1}</a></li>
                                        ))
                                        }
                                    </ul>
                                </nav>
                                
                            </div>
                        :
                        <PageLoadSpinner />
                        }

                    </div>

                </div>
        </div>
    )
}

export { ManageUsersScreen }