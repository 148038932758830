import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faPencil, faPlus, faTrash, faWarning } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import InputField from 'components/inputs/InputField'
import { FormInputError } from 'IndexProductScreen'
import { toast } from 'bulma-toast'
import { API_URL, flowControlDeviceTypes, prettyDisplayValue } from 'constants/Constants'
import { SearchBar } from 'components/inputs/SearchBar'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { BaseModal } from 'components/modals/BaseModal'
import { ConfirmActionModal } from 'components/modals/ConfirmActionModal'


export type FlowControlDeviceConfig = {
    id: string,
    type: string, // TODO - fix
	size: number,
	manufacturer?: string
}

type FlowControlDeviceConfigFormState = {
    type: string,
	size: number,
	manufacturer?: string, 
}

const initialFormState: FlowControlDeviceConfigFormState = {
    type: '',
	size: 0,
	manufacturer: '',
}

const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

interface FormErrorState {
    type: FormInputError,
	size: FormInputError,
	manufacturer: FormInputError,
}

const initialFormErrorState: FormErrorState = {
    type: initialFormInputErrorState,
	size: initialFormInputErrorState,
	manufacturer: initialFormInputErrorState
}

const ManageFlowControlDevidesScreen = ({

}:{

}) => {


    const { getAccessTokenSilently } = useAuth0()

    const [didFlowControlDevicesUpdate, setDidFlowControlDevicesUpdate] = useState<boolean>(false)
    const [isConfigRequestLoading, setIsConfigRequestLoading] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<FlowControlDeviceConfig>(null)
    const [itemToEdit, setItemToEdit] = useState<FlowControlDeviceConfig>(null)
    const [isInEditState, setIsInEditState] = useState<boolean>(false)
    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [isConfirmEditModalActive, setIsConfirmEditModalActive] = useState<boolean>(false)
    const [initialFlowControlDevices, setInitialFlowControlDevices] = useState<FlowControlDeviceConfig[]>(null)
    const [filterableFlowControlDevices, setFilterableFlowControlDevices] = useState<FlowControlDeviceConfig[]>(null)
    const [isFlowControlDeviceFormModalActive, setIsFlowControlDeviceFormModalActive] = useState<boolean>(false)
    const [flowControlDeviceConfigFormState, setFlowControlDeviceConfigFormState] = useState<FlowControlDeviceConfigFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<FormErrorState>(initialFormErrorState)

    // TODO - clear this up and move hook.
    useEffect(() => {  

        const getFlowControlDevices = async () => {

            const accessToken: string = await getAccessTokenSilently()
    
              const response = await fetch(
                `${API_URL}/configuration/flow-control-devices`
                    , {
                    method: 'GET',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    }
              )
         
              const flowControlDevicesResp: FlowControlDeviceConfig[] = await response.json()
              const sortedFlowControlDevices: FlowControlDeviceConfig[] = 
              flowControlDevicesResp
                .sort((a, b) => a.type.localeCompare(b.type) || a.size - b.size)
              
              setInitialFlowControlDevices(sortedFlowControlDevices)
              setFilterableFlowControlDevices(sortedFlowControlDevices)
              setDidFlowControlDevicesUpdate(false)
          }
      
          getFlowControlDevices()
        //   .catch((error) => setSearchError(error))    
    }, [didFlowControlDevicesUpdate])

    const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterableFlowControlDevices(
            initialFlowControlDevices.filter(item =>  item.type.toLowerCase().split("_").join(" ").startsWith(e.target.value.toLowerCase()))
        )
    }
    
    const onClearSearchQuery = (e: React.MouseEvent) => {
        setFilterableFlowControlDevices(initialFlowControlDevices)
    }

    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setFlowControlDeviceConfigFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
        setIsFlowControlDeviceFormModalActive(false)
        setIsInEditState(false)
        setItemToEdit(null)
    }

    const onCancelConfirmDeleteModal = () => {
        setIsConfirmDeleteModalActive(false)
        setItemToDelete(null)
    }

    const onDeleteFlowControlDeviceItem = async (e: React.MouseEvent, itemToDelete: FlowControlDeviceConfig) => {
        e.preventDefault(); 
        setItemToDelete(itemToDelete)
        setIsConfirmDeleteModalActive(true) 
    }

    const onEditFlowControlDeviceItem = async (e: React.MouseEvent, itemToEdit: FlowControlDeviceConfig) => {
        e.preventDefault(); 

        setIsInEditState(true)
        setItemToEdit(itemToEdit)

        setFlowControlDeviceConfigFormState({
            ...itemToEdit,
        })

        setIsFlowControlDeviceFormModalActive(true)
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        setFlowControlDeviceConfigFormState({
            ...flowControlDeviceConfigFormState,
            [e.target.name]: !isNaN(parseInt(e.target.value))  ? parseInt(e.target.value) : e.target.value
        })
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustBeGreaterThanZero: string = `Must be greater than zero`

        const formErrors: FormErrorState = Object.entries(flowControlDeviceConfigFormState).reduce((acc, [key, value]) => {

            if (['manufacturer'].includes(key)) {
                return {
                    ...acc,
                    [key]: {
                        isError: false,
                        errorMessage: null
                    }
                }
            }
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '' || (!isNaN(parseInt(value.toString())) && parseInt(value.toString()) <= 0),
                    errorMessage: value === null || value === '' ? mustNotBeEmptyError : (!isNaN(parseInt(value.toString())) && parseInt(value.toString()) <= 0)  ? mustBeGreaterThanZero : null,
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false
        })
    }

    const onSubmitFormEdit = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const editedFlowControlDevice: FlowControlDeviceConfig = {
                id: itemToEdit.id,
                ...flowControlDeviceConfigFormState
            }

            const accessToken: string = await getAccessTokenSilently()
            
            setIsConfigRequestLoading(true)

            const response = await fetch(
                `${API_URL}/configuration/flow-control-devices`
                    , {
                    method: 'PATCH',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(editedFlowControlDevice)
                }); 

                if (response.status === 200) {
                    toast({
                        message: `Success, flow control device updated.`,
                        type: 'is-link',
                        position: 'bottom-right',
                        })

                        setIsConfigRequestLoading(false)
                        onCancelModal(e)
                        setIsConfirmEditModalActive(false)
                        setDidFlowControlDevicesUpdate(true)
                        
                } else {
                    setIsConfigRequestLoading(false)

                    toast({
                        message: 'Oh no, something went wrong. Please try again.',
                        type: 'is-danger',
                        position: 'bottom-right',
                        })
                }
            }
    }


    const onSubmitFormAdd = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            console.log("pressed")

            const accessToken: string = await getAccessTokenSilently()
            
            setIsConfigRequestLoading(true)

            const response = await fetch(
                `${API_URL}/configuration/flow-control-devices`
                    , {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(flowControlDeviceConfigFormState)
                }); 

                if (response.status === 200) {
                    toast({
                        message: `Success, flow control device added.`,
                        type: 'is-link',
                        position: 'bottom-right',
                        })

                        setIsConfigRequestLoading(false)
                        onCancelModal(e)
                        setDidFlowControlDevicesUpdate(true)
                        
                } else {
                    setIsConfigRequestLoading(false)

                    toast({
                        message: 'Oh no, something went wrong. Please try again.',
                        type: 'is-danger',
                        position: 'bottom-right',
                        })
                }
            }
    }

    const deleteFlowControlDevice = async (e: React.MouseEvent) => {
        e.preventDefault()

        const accessToken: string = await getAccessTokenSilently()

        setIsConfigRequestLoading(true)
        
        const response = await fetch(
            `${API_URL}/configuration/flow-control-devices`
                , {
                method: 'DELETE',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(itemToDelete)
                }
        )

        if (response.status === 200) {
            toast({
                message: `Success, flow control device deleted.`,
                type: 'is-link',
                position: 'bottom-right',
              })

            setIsConfigRequestLoading(false)
            setDidFlowControlDevicesUpdate(true)
            onCancelConfirmDeleteModal()
               
        } else {
            setIsConfigRequestLoading(false)

            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
              })
        }

    }
     
    return(
        <div>
            <BaseModal
                isModalActive={isFlowControlDeviceFormModalActive}
                modalTitle={`${isInEditState ? `Edit `: `Add New `} Flow Control Device`}
                onCancelModal={onCancelModal}>
                    <form>
                        <div className="field">
                            <label className="label">Type</label> 
                            <div className={`select ${formErrorState.type && formErrorState.type.isError ? `is-danger`: ''}`}>
                                <select name="type" value={flowControlDeviceConfigFormState.type} onChange={onChangeInput}>
                                    <option value="" disabled={true} >Select a type</option>
                                    {flowControlDeviceTypes?.map((fcd, idx) => 
                                        <option key={`fcd-type-${idx}`} value={fcd.value}>{fcd.label}</option>
                                    )}
                                </select>
                            </div>
                            {formErrorState.type && formErrorState.type.isError &&
                                <span className="help is-danger">{formErrorState.type.errorMessage}</span>
                            }
                        </div>

                        <InputField
                            fieldLabel={`Size`}
                            onChange={onChangeInput}
                            fieldName={'size'}
                            value={flowControlDeviceConfigFormState.size}
                            errorValue={formErrorState.size}
                            helpText={``}
                            isNumber={true}
                            hasAddOn={`mm`}
                            isExpanded={true} />    

                        {(flowControlDeviceConfigFormState.type === 'FLAP_VALVE' || flowControlDeviceConfigFormState.type === 'PENSTOCK') &&
                            <InputField
                                fieldLabel={`Manufacturer`}
                                onChange={onChangeInput}
                                fieldName={'manufacturer'}
                                value={flowControlDeviceConfigFormState.manufacturer}
                                errorValue={formErrorState.manufacturer}
                                helpText={`The manufacturer of the flow control device`}
                                isOptional={true}
                                isExpanded={true} /> 
                        }
                                    
                        <div className="field is-grouped is-pulled-right pt-4">
                            <div className="control">
                                <button className={`button is-link ${isConfigRequestLoading ? `is-loading` : ``}`} onClick={isInEditState  ? (e) => { e.preventDefault(); setIsConfirmEditModalActive(true) } : onSubmitFormAdd}>{isInEditState ? `Edit` : `Add`}</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={onCancelModal}>Cancel</button>
                            </div>
                        </div>

                    </form>
            </BaseModal>

        <ConfirmActionModal 
            isModalActive={isConfirmDeleteModalActive}
            isLoading={isConfigRequestLoading}
            title={`Are you sure you wish to continue?`}
            subtitle={`By deleting this flow control device you will delete it for all products which currently use it.`}
            Icon={<FontAwesomeIcon icon={faWarning} size={`3x`} />}
            confirmBtnText={`Continue`}
            onConfirmAction={deleteFlowControlDevice} 
            onCancel={onCancelConfirmDeleteModal} />

        <ConfirmActionModal 
            isModalActive={isConfirmEditModalActive}
            isLoading={isConfigRequestLoading}
            title={`Are you sure you wish to continue?`}
            subtitle={`By editing this flow control device you will update it for all products which currently use it.`}
            Icon={<FontAwesomeIcon icon={faWarning} size={`3x`} />}
            confirmBtnText={`Continue`}
            onConfirmAction={onSubmitFormEdit} 
            onCancel={() => setIsConfirmEditModalActive(false)} />

        <div className="columns mb-6">
            <div className="column is-10">
                    <h1 className="title is-3">Manage Flow Control Devices</h1>
                    <h1 className="subtitle is-5 mb-3"></h1>
            </div>
            <div className="column">
                <button className="button is-link is-pulled-right" onClick={() => setIsFlowControlDeviceFormModalActive(true)}>Add <FontAwesomeIcon className="pl-2" icon={faPlus} /></button>
            </div>
        </div>

        {initialFlowControlDevices && initialFlowControlDevices.length > 0 
        ?
        <>
        <div className="columns mb-4 pb-4">
            <div className="column is-5 p-0">
                <SearchBar 
                    onChange={onChangeSearchQuery}
                    onClear={onClearSearchQuery} />
            </div>
        </div>

        {filterableFlowControlDevices && filterableFlowControlDevices.length > 0
        ?
        <div className="columns">
            <div className="column is-12">
                <div className="block">

                {/* TODO: Can be pulled out into a common table header component */}
                <div className="columns is-vcentered" style={{ borderBottom: '1px solid rgb(228, 228, 228)', backgroundColor: '#fafafa' }}>
                    <div className="column is-3">
                        <ColumnLabel label={`Type`} />
                    </div>
                    <div className="column is-3">
                        <ColumnLabel label={`Size`} />
                    </div>
                    <div className="column is-3">
                        <ColumnLabel label={`Manufacturer`} />
                    </div>
                    <div className="column is-1">
                        {/* Leave space for actions button. */}
                    </div>
                </div>

                    {filterableFlowControlDevices
                    .sort((a, b) => {
                        if (a.type.toUpperCase() > b.type.toUpperCase()) return 1;
                        if (a.type.toUpperCase() < b.type.toUpperCase()) return -1;
                        return 0;
                    })
                    .map((item, idx) => (
                        <div className={`columns is-vcentered pb-1 pt-1`} key={`code-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                            <div className="column is-3">
                                {prettyDisplayValue(item.type)}
                            </div>   
                            <div className="column is-3">
                                {`${item.size}mm`}
                            </div> 
                            <div className="column is-3">
                                {`${item.manufacturer || `--`}`}
                            </div>
                            <div className="column is-1">
                                <div className="dropdown is-hoverable is-pulled-right">
                                    <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                        <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                        <div className="dropdown-content">
                                            <a href="#" className="dropdown-item" onClick={(e) => onDeleteFlowControlDeviceItem(e, item)}>
                                                <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                            </a>
                                            <hr className="navbar-divider"/>
                                            <a href="#" className="dropdown-item" onClick={(e) => onEditFlowControlDeviceItem(e, item)}>
                                                <span><FontAwesomeIcon icon={faPencil} className="pr-2" /> Edit</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                   
                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
        :
        <div className="has-text-centered">
            <p>No flow control device(s) found.</p>
        </div>
        }
        </>
        :
        <PageLoadSpinner />
    }         
    </div>
    )
}

export { ManageFlowControlDevidesScreen }