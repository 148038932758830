import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { prettyDisplayValue } from 'constants/Constants'
import { SearchFilterSelection } from 'hooks/useProductFilters'

const FacetFilterCheckBox = ({ 
  facetValue,
  count,
  label,
  isChecked,
  onChange,
} : { 
  facetValue: string,
  count: number,
  label: string,
  isChecked: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}) => {
  return(
    <div className="field">
    <input className="is-checkradio is-small is-info" id={`checkbox-${facetValue}`} type="checkbox" name={`checkbox-${facetValue}`} value={facetValue} onChange={onChange} checked={isChecked} />
    <label htmlFor={`checkbox-${facetValue}`}>
      <span className="ml-0">{label}</span>
      <span className="ml-2 tag is-rounded">{count}</span>
    </label>
  </div>
  )
}

const SearchFilter = ({
    filterLabel,
    filterName,
    facet,
    hasNoneFacet,
    searchFilterSelection,
    onCheckFilter,
    onCheckNoneFilter,
    overrideFacetValueDisplayFn,
    facetValueMetric,
    displayPretty = false,
    isSmall = false
  } : {
    filterLabel: string,
    filterName: string,
    facet: Record<string, number>,
    hasNoneFacet?: Record<string, number>,
    searchFilterSelection?: SearchFilterSelection,
    onCheckFilter: (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => void,
    onCheckNoneFilter?: (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => void, 
    overrideFacetValueDisplayFn?: (facetValue: string) => string,
    facetValueMetric?: string,
    displayPretty?: boolean,
    isSmall?: boolean
  }) => {
  
    const [isDropdownActive, setIsDropdownActive] = useState(false)
  
    const onClickDropdown = () => {
      setIsDropdownActive(!isDropdownActive)
    }

    const isFilterApplied: boolean = searchFilterSelection.appliedFilters.length > 0 || searchFilterSelection?.hasNoneSelected 
  
    return(
        <div className={`dropdown is-hoverable`}>
          
          <div className="dropdown-trigger">
            <button className={`button is-rounded ${isSmall ? `is-small` : ''}`} style={{...(isFilterApplied && {border: 'hsl(204, 86%, 53%) 1px solid'})}} aria-haspopup="true" aria-controls={`search-filter-${filterName}`} onClick={onClickDropdown}>
              <span>{filterLabel}</span>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </button>
          </div>
  
          <div className="dropdown-menu" id={`search-filter-${filterName}`} role="menu">
            <div className="dropdown-content">
              <div className="dropdown-item">
                {facet &&
                  Object.entries(facet).map( ([facetValue, count]) => {
                    return (
                      <FacetFilterCheckBox
                        key={facetValue}
                        facetValue={facetValue}
                        count={count}
                        label={
                          overrideFacetValueDisplayFn 
                            ? overrideFacetValueDisplayFn(facetValue)
                            :`${displayPretty ? prettyDisplayValue(facetValue) : facetValue}${facetValueMetric ? facetValueMetric : ``}`
                        }
                        isChecked={searchFilterSelection.appliedFilters.includes(facetValue)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheckFilter(e, filterName, facetValue)} />
                    )
                  })
                }
                {hasNoneFacet &&
                  Object.entries(hasNoneFacet).filter(_ => _[0] === 'false').map( ([facetValue, count]) => {

                    const label = `No ${filterLabel}`

                    return (
                      <FacetFilterCheckBox
                        key={label}
                        facetValue={label}
                        count={count}
                        label={label}
                        isChecked={searchFilterSelection.hasNoneSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheckNoneFilter(e, filterName, facetValue)} />
                    )
                  })
                }
              </div>                  
            </div>
          </div>
  
        </div>
    )
  }

  export { SearchFilter }