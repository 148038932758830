import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from 'ManageUsersScreen'
import { DATE_TIME_FORMAT } from 'constants/Constants'
import moment from 'moment'
import React from 'react'

// e.g November 24th 2024, 12:38:13
const prettyDateFromTimestamp = (date: string): string => {
    return moment(date, DATE_TIME_FORMAT).format(`MMMM Do YYYY [at] hh:mma`)
}

const Comment = ({
    messageStyle,
    commenterName,
    loggedInUser,
    actionText,
    timestamp,
    comment,
    ComponentPulledRight,
}:{
    messageStyle: `is-dark` | `is-info` | `is-success`,
    commenterName: string,
    loggedInUser: User,
    actionText: string,
    timestamp: string,
    comment: string,
    ComponentPulledRight?: React.ReactNode
}) =>  {

    return(
        <div className={`message ${messageStyle}`}>
            <div className="message-body p-5">
                <div className="columns is-vcentered pb-0">
                    <div className="column is-narrow p-0">
                        <FontAwesomeIcon icon={faCircleUser} size={'2x'} />
                    </div>
                    <div className="column is-narrow pb-1">
                        <span className="pl-1 has-text-weight-semibold">{`${commenterName} ${commenterName === loggedInUser.name ? `(You)` : ``}`}</span> {actionText}
                        <p className="pl-1 is-size-7 has-text-weight-light">{prettyDateFromTimestamp(timestamp)}</p>
                    </div>
                    {ComponentPulledRight &&
                        <div className="column">
                            {ComponentPulledRight}
                        </div>
                    }
                </div>
                {comment && 
                    <div className="columns is-vcentered">
                        <div className="column pt-0 pl-6 pb-1">
                            <>
                                <span className="is-italic is-size-5">"</span>
                                <span className="is-italic is-size-6">{comment}</span>
                                <span className="is-italic is-size-5">"</span>
                            </>
                        </div>
                    </div>
                }
            </div>
      </div>
    )
}

export { Comment }