import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { ConfigValueItem } from 'constants/Constants'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal'
import InputField from 'components/inputs/InputField'
import { FormInputError } from 'IndexProductScreen'
import { toast } from 'bulma-toast'
import { API_URL } from 'constants/Constants'
import { SearchBar } from 'components/inputs/SearchBar'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { BaseModal } from 'components/modals/BaseModal'

{/* TODO: Can be pulled out into a common table header component */}
const ConfigTableHeaders = () => {

    return (
        <div className="columns is-vcentered" style={{ borderBottom: '1px solid rgb(228, 228, 228)', backgroundColor: '#fafafa' }}>
            <div className="column is-3">   
                <ColumnLabel label={`Label`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Value`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Weight (KG)`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`External Back Height (mm)`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Internal Back Width (mm)`} />
            </div>
            <div className="column is-1">
                {/* Leave space for actions button. */}
            </div>
        </div>
    )
}

type ConfigItemFormState = {
    label: string,
    value: string,
    shortDescription?: string,
    weight: number,
    externalBackHeight: number,
    internalBackWidth: number,
}

const initialFormState: ConfigItemFormState = {
    label: '',
    value: '',
    shortDescription: '',
    weight: 0,
    externalBackHeight: 0,
    internalBackWidth: 0,
}

const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

interface FormErrorState {
    label: FormInputError,
    value: FormInputError,
    shortDescription: FormInputError,
    weight: FormInputError,
    externalBackHeight: FormInputError,
    internalBackWidth: FormInputError
}

const initialFormErrorState: FormErrorState = {
    label: initialFormInputErrorState,
    value: initialFormInputErrorState,
    shortDescription: initialFormInputErrorState,
    weight: initialFormInputErrorState,
    externalBackHeight: initialFormInputErrorState,
    internalBackWidth: initialFormInputErrorState,
}

const ManageProductTypesScreen = ({

}:{


}) => {

    const { getAccessTokenSilently } = useAuth0()

    const [didProductTypesUpdate, setDidProductTypesUpdate] = useState<boolean>(false)
    const [isConfigRequestLoading, setIsConfigRequestLoading] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<ConfigValueItem>(null)
    const [isInEditState, setIsInEditState] = useState<boolean>(false)
    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [initialProductTypes, setInitialProductTypes] = useState<ConfigValueItem[]>(null)
    const [filterableProductTypes, setFilterableProductTypes] = useState<ConfigValueItem[]>(null)
    const [isProductTypeFormModalActive, setIsProductTypeFormModalActive] = useState<boolean>(false)
    const [configItemFormState, setConfigItemFormState] = useState<ConfigItemFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<FormErrorState>(initialFormErrorState)

    // TODO - clear up and move to hook
    useEffect(() => {

        const getProductTypes = async () => {

            const accessToken: string = await getAccessTokenSilently()
    
            const response = await fetch(
            `${API_URL}/configuration/product-types`
                , {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                }
            )
        
            const productTypes: ConfigValueItem[] = await response.json()
            const nonDeletedProductTypes: ConfigValueItem[] = 
            productTypes
                .filter(_ => !_.isDeleted) 
                .sort((a,b) => a.value.localeCompare(b.value))
                
            setInitialProductTypes(nonDeletedProductTypes)
            setFilterableProductTypes(nonDeletedProductTypes)
            setDidProductTypesUpdate(false)
        }
      
          getProductTypes()
        //   .catch((error) => setSearchError(error))    
    }, [didProductTypesUpdate])
    

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        setConfigItemFormState({
            ...configItemFormState,
            [e.target.name]: e.target.value
        })
    }

    const onCancelConfirmDeleteModal = () => {
        setIsConfirmDeleteModalActive(false)
        setItemToDelete(null)
    }

    const onDeleteProductTypeItem = async (e: React.MouseEvent, itemToDelete: ConfigValueItem) => {
        e.preventDefault(); 
        setItemToDelete(itemToDelete)
        setIsConfirmDeleteModalActive(true) 
    }

    const onEditProductTypeItem = async (e: React.MouseEvent, itemToEdit: ConfigValueItem) => {
        e.preventDefault(); 

        setIsInEditState(true)

        setConfigItemFormState({
            ...itemToEdit,
            weight: itemToEdit?.weight?.value,
            externalBackHeight: itemToEdit?.externalBackHeight?.value,
            internalBackWidth: itemToEdit?.internalBackWidth?.value,
            shortDescription: itemToEdit?.shortDescription || ''
        })

        setIsProductTypeFormModalActive(true)
    }
   
    const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterableProductTypes(initialProductTypes.filter(item => item.value.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }
    
    const onClearSearchQuery = (e: React.MouseEvent) => {
        setFilterableProductTypes(initialProductTypes)
    }

    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setConfigItemFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
        setIsProductTypeFormModalActive(false)
        setIsInEditState(false)
    }

    const deleteProductType = async () => {

        setIsConfigRequestLoading(true)

        const accessToken: string = await getAccessTokenSilently()
        
        const response = await fetch(
            `${API_URL}/configuration/product-types`
                , {
                method: 'DELETE',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(itemToDelete)
                }
        )

        if (response.status === 200) {
            toast({
                message: `Success, product type deleted.`,
                type: 'is-link',
                position: 'bottom-right',
              })

            setIsConfigRequestLoading(false)
            setDidProductTypesUpdate(true)
            onCancelConfirmDeleteModal()
              
        } else {
            setIsConfigRequestLoading(false)

            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
              })
        }
     
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustNotBeZeroError: string = `Must not be zero`

        const isError = (value: string | number): boolean => value === null || value === '' || value === 0

        const formErrors: FormErrorState = Object.entries(configItemFormState).reduce((acc, [key, value]) => {

            if (key === 'shortDescription') {
                return {
                    ...acc,
                    [key]: {
                        isError: false,
                        errorMessage: null,
                    }
                }
            }

            if (key === 'weight' || key === 'externalBackHeight' || key === 'internalBackWidth') {
                return {
                    ...acc,
                    [key]: {
                        isError: isError(value),
                        errorMessage: isError(value) ? mustNotBeZeroError : null,
                    }
                }
            }
            
            return {
                ...acc,
                [key]: {
                    isError: isError(value),
                    errorMessage: isError(value) ? mustNotBeEmptyError : null,
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false
        })
    }


    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const productType: ConfigValueItem = {
                ...configItemFormState, 
                weight: {
                    value: configItemFormState.weight,
                    unitOfMeasurement: 'kg'
                },
                externalBackHeight: {
                    value: configItemFormState.externalBackHeight,
                    unitOfMeasurement: 'mm'
                },
                internalBackWidth: {
                    value: configItemFormState.internalBackWidth,
                    unitOfMeasurement: 'mm'
                }
            }

            const accessToken: string = await getAccessTokenSilently()
            
            setIsConfigRequestLoading(true)

            if (isInEditState) {

                const response = await fetch(
                    `${API_URL}/configuration/product-types`
                        , {
                        method: 'PATCH',
                        redirect: 'follow',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": 'application/json'
                        },
                        body: JSON.stringify(productType)
                    }); 
    
                if (response.status === 200) {
                    toast({
                        message: `Success, product type updated.`,
                        type: 'is-link',
                        position: 'bottom-right',
                      })
    
                      setIsConfigRequestLoading(false)
                      onCancelModal(e)
                      setDidProductTypesUpdate(true)
                      
                } else {
                    setIsConfigRequestLoading(false)
    
                    toast({
                        message: 'Oh no, something went wrong. Please try again.',
                        type: 'is-danger',
                        position: 'bottom-right',
                      })
                }

            } else {

                const response = await fetch(
                    `${API_URL}/configuration/product-types`
                        , {
                        method: 'POST',
                        redirect: 'follow',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": 'application/json'
                        },
                        body: JSON.stringify(productType)
                    }); 
    
                if (response.status === 200) {
                    toast({
                        message: `Success, product type added.`,
                        type: 'is-link',
                        position: 'bottom-right',
                      })
    
                      setIsConfigRequestLoading(false)
                      onCancelModal(e)
                      setDidProductTypesUpdate(true)
                      
                } else {
                    setIsConfigRequestLoading(false)
    
                    toast({
                        message: 'Oh no, something went wrong. Please try again.',
                        type: 'is-danger',
                        position: 'bottom-right',
                      })
                }

            }
        }
    }

    return(
        <div>  
            <BaseModal
                isModalActive={isProductTypeFormModalActive}
                modalTitle={`${isInEditState ? `Edit `: `Add New `} Product Type`}
                onCancelModal={onCancelModal}>
                    <form>

                        <InputField
                            fieldLabel={`Value`}
                            onChange={onChangeInput}
                            fieldName={'value'}
                            value={configItemFormState.value}
                            errorValue={formErrorState.value}
                            helpText={``}
                            isDisabled={isInEditState} />

                        <InputField
                            fieldLabel={`Label`}
                            onChange={onChangeInput}
                            fieldName={'label'}
                            value={configItemFormState.label}
                            errorValue={formErrorState.label}
                            helpText={``} />

                        <InputField
                            fieldLabel={`Weight`}
                            onChange={onChangeInput}
                            fieldName={'weight'}
                            value={configItemFormState.weight}
                            errorValue={formErrorState.weight}
                            hasAddOn={'kg'}
                            isNumber={true}
                            helpText={``} />
                            
                        <InputField
                            fieldLabel={`External Back Height`}
                            onChange={onChangeInput}
                            fieldName={'externalBackHeight'}
                            value={configItemFormState.externalBackHeight}
                            errorValue={formErrorState.externalBackHeight}
                            hasAddOn={'mm'}
                            isNumber={true}
                            helpText={``} />

                        <InputField
                            fieldLabel={`Internal Back Width`}
                            onChange={onChangeInput}
                            fieldName={'internalBackWidth'}
                            value={configItemFormState.internalBackWidth}
                            errorValue={formErrorState.internalBackWidth}
                            hasAddOn={'mm'}
                            isNumber={true}
                            helpText={``} />
                                                                    
                        <InputField
                            fieldLabel={`Short Description`}
                            onChange={onChangeInput}
                            fieldName={'shortDescription'}
                            value={configItemFormState.shortDescription}
                            errorValue={formErrorState.shortDescription}
                            isOptional={true}
                            helpText={``} />

                        <div className="field is-grouped is-pulled-right pt-4">
                            <div className="control">
                                <button className={`button is-link ${isConfigRequestLoading ? `is-loading` : ``}`} onClick={onSubmitForm}>{isInEditState ? `Edit` : `Add`}</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={onCancelModal}>Cancel</button>
                            </div>
                        </div>

                    </form>
            </BaseModal>

        <ConfirmDeleteModal 
            isModalActive={isConfirmDeleteModalActive}
            isLoading={isConfigRequestLoading}
            onCancel={onCancelConfirmDeleteModal}
            onConfirmDelete={deleteProductType} />

        <div className="columns mb-6">
            <div className="column is-10">
                    <h1 className="title is-3">Manage Product Types</h1>
                    <h1 className="subtitle is-5 mb-3"></h1>
            </div>
            <div className="column">
                <button className="button is-link is-pulled-right" onClick={() => setIsProductTypeFormModalActive(true)}>Add <FontAwesomeIcon className="pl-2" icon={faPlus} /></button>
            </div>
        </div>

        {initialProductTypes && initialProductTypes.length > 0 
        ?
        <>
        <div className="columns mb-4 pb-4">
            <div className="column is-5 p-0">
                <SearchBar 
                    onChange={onChangeSearchQuery}
                    onClear={onClearSearchQuery} />
            </div>
        </div>

        {filterableProductTypes && filterableProductTypes.length > 0
        ?
        <div className="columns">
            <div className="column is-12">
                <div className="block">

                    <ConfigTableHeaders />

                    {filterableProductTypes.map((item, idx) => (
                        <div className={`columns is-vcentered pb-1 pt-1 ${item.isDeleted ? `has-background-white-bis` : ``}`} key={`code-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                            <div className="column is-3">
                                {item.label}
                            </div>
                            <div className="column is-2">
                                {item.value}
                            </div>   
                            <div className="column is-2">
                                {item.weight && `${item.weight.value}${item.weight.unitOfMeasurement}` || `--`}
                            </div> 
                            <div className="column is-2">
                                {item.externalBackHeight && `${item.externalBackHeight.value}${item.externalBackHeight.unitOfMeasurement}` || `--`}
                            </div> 
                            <div className="column is-2">
                                {item.internalBackWidth && `${item.internalBackWidth.value}${item.internalBackWidth.unitOfMeasurement}` || `--`}
                            </div> 
                            {item.isDeleted
                                ?
                                <span className="tag is-danger is-light">Deleted</span>
                                :
                                <div className="column is-narrow">
                                <div className="dropdown is-hoverable is-pulled-right">
                                    <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                        <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                        <div className="dropdown-content">
                                            <a href="#" className="dropdown-item" onClick={(e) => onDeleteProductTypeItem(e, item)}>
                                                <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                            </a>
                                            <hr className="navbar-divider"/>
                                            <a href="#" className="dropdown-item" onClick={(e) => onEditProductTypeItem(e, item)}>
                                                <span><FontAwesomeIcon icon={faPencil} className="pr-2" /> Edit</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }       
                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
        :
        <div className="has-text-centered">
            <p>No product type(s) found.</p>
        </div>
        }
        </>
        :
        <PageLoadSpinner />
    }         
    </div>
    )
}

export { ManageProductTypesScreen }