import { useEffect, useState } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

export type SearchFilterSelection = {
  appliedFilters: string[],
  hasNoneSelected?: boolean
}

export type SearchFilters = Record<string, SearchFilterSelection>

export const useSearchQueryAndFilters = (selectedSearchIndex: 'products' | 'drawings') => {

  const productFacetToHasSearchParamMap: { [key: string]: string } = {
    toe: 'hasToe',
    steelworkCode: 'hasSteelwork',
    secondaryCasting: 'hasSecondaryCasting',
    reinforcing: 'hasReinforcing',
    handrail: 'hasHandrail',
    flowControlDeviceSize: 'hasFlowControlDeviceSize',
    flowControlDeviceType: 'hasFlowControlDeviceType',
    invertLevel: 'hasPipeOpeningInvertLevel',
    openingLevel: 'hasPipeOpeningOpeningLevel',
    openingSize: 'hasPipeOpeningSize',
    insideDiameter: 'hasPipeOpeningInsideDiameter',
    pipeMaterial: 'hasPipeOpeningMaterial'
  }

    const [urlSearchParams, setUrlSearchParams] = useSearchParams()

    const [searchQuery, setSearchQuery] = useState<string>('')

    const [productFilters, setProductFilters] = useState<SearchFilters>({
        steelworkCode: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        handrail: {
          appliedFilters: [],
          hasNoneSelected: false, 
        },
        productType: {
          appliedFilters: []
        },
        toe: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        flowControlDeviceSize: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        flowControlDeviceType: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        secondaryCasting: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        reinforcing: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        invertLevel: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        openingLevel: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        openingSize: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        insideDiameter: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        pipeMaterial: {
          appliedFilters: [],
          hasNoneSelected: false,
        }
    })

    const [drawingFilters, setDrawingFilters] = useState<SearchFilters>({
      type: {
        appliedFilters: []
      }
    })

    useEffect(() => {

      const {q, ...searchParams} = Object.fromEntries(urlSearchParams)

      if (selectedSearchIndex === 'drawings') {
  
        const filters: SearchFilters = Object.fromEntries(Object.entries(drawingFilters).map(([key, value]) => {
          return [key, {
            ...value,
            ...(searchParams[key] ? { appliedFilters: [searchParams[key]] } : { appliedFilters: [] })
          }]
        }))
        
        setDrawingFilters(filters)
        
      } else {

        const filters: SearchFilters = Object.fromEntries(Object.entries(productFilters).map(([key, value]) => {
          return [key, {
            ...value,
            ...(searchParams[key] ? { appliedFilters: [searchParams[key]] } : { appliedFilters: [] }),
            ...(searchParams[productFacetToHasSearchParamMap[key]] && searchParams[productFacetToHasSearchParamMap[key]] === 'false' ? { hasNoneSelected: true } : { hasNoneSelected: false }),
          }]
        }))
        
        setProductFilters(filters)     

      }

      setSearchQuery(q || '')
  
    }, [urlSearchParams, selectedSearchIndex])

    const onClickSearch = () => {
      const searchParams = Object.fromEntries(urlSearchParams)
      setUrlSearchParams({...searchParams, q: searchQuery})
    }

    const onClearSearch = () => {
      const {q, ...searchParams} = Object.fromEntries(urlSearchParams)
      setUrlSearchParams({...searchParams})
    }

    const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.currentTarget.value)
    }

    const onClearFilters = () => {
      const searchParams = Object.fromEntries(urlSearchParams)
      const {q, ...remaining} = searchParams
      setUrlSearchParams({...(q && {q: q})})
    }

    const onClearSearchQuery = () => {
      setSearchQuery('')
    }
  
    const onCheckFilter = (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => {

      const searchParams = Object.fromEntries(urlSearchParams)
      
      const filters: SearchFilters = selectedSearchIndex === 'products' ? productFilters : drawingFilters

      if (e.currentTarget.checked) {
  
        setUrlSearchParams({
          ...searchParams,
          [facet]: [...filters[facet].appliedFilters, facetValue].toString()
        })
  
      } else {
        const appliedFiltersForFacetAfterUpdate = filters[facet].appliedFilters.filter(_ => _ !== facetValue)
  
        if (appliedFiltersForFacetAfterUpdate.length === 0) {
          const {[facet]: _, ...remaining} = searchParams
          setUrlSearchParams(remaining)
        } else {
          setUrlSearchParams({
            ...searchParams,
            [facet]: appliedFiltersForFacetAfterUpdate.toString()
          })
        }
  
      }
    }

    const onCheckNoneFilter = (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => {
     
      const searchParams = Object.fromEntries(urlSearchParams)

      if (e.currentTarget.checked) {

        setUrlSearchParams({
          ...searchParams,
          [productFacetToHasSearchParamMap[facet]]: 'false'
        })

      } else {

        const {[productFacetToHasSearchParamMap[facet]]: _, ...remaining} = searchParams
          setUrlSearchParams(remaining)
      }

    }

    return {
        productFilters,
        drawingFilters,
        searchQuery, 
        onChangeSearchQuery,
        onClearFilters,
        onClearSearchQuery,
        onCheckFilter,
        onCheckNoneFilter,
        onClearSearch,
        onClickSearch
    }
}