import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ConfirmActionModal = ({
    title,
    subtitle,
    Icon,
    confirmBtnText,
    isModalActive,
    isLoading,
    onConfirmAction,
    onCancel,
}: {
    title: string,
    subtitle: string,
    Icon: JSX.Element,
    confirmBtnText: string,
    isModalActive: boolean,
    isLoading: boolean,
    onConfirmAction: (e: React.MouseEvent) => void,
    onCancel: () => void

}) => {

    return(
    <div className={`modal ${isModalActive ? `is-active`: ``}`}>
        <div className="modal-background"></div>

        <div className="modal-card box">

            <div className="modal-card-body p-5">
                <div className="columns is-vcentered p-4">
                    <div className="column is-narrow">
                        {Icon}
                    </div>
                    <div className="column">
                        <h1 className="title is-4">{title}</h1>
                        <h2 className="subtitle is-6">{subtitle}</h2>
                    </div>
                </div>
                
                <div className="field is-grouped is-pulled-right">
                    <div className="control">
                        <button className={`button is-link ${isLoading ? `is-loading` : ``}`} onClick={onConfirmAction}>{confirmBtnText}</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light" onClick={onCancel}>Cancel</button>
                    </div>
                </div>

                
            </div>
        </div>

    </div>
    )

}

export { ConfirmActionModal }