import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'
import { ProductSearchResult } from 'models/SearchResult'

export interface IUseProductVersions {
    productVersions: ProductSearchResult[],
    isLoading: boolean,
    getProductVersions: (productId: string) => void,
}

export const useProductVersions = () => {

    const [productVersions, setProductVersions] = useState<ProductSearchResult[]>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getProductVersions = async (productId: string) => {

        setIsLoading(true)
  
        const accessToken: string = await getAccessTokenSilently()
  
        const response = await fetch(
        `${API_URL}/products/get/${productId}/versions`
            , {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            }
        )
    
        const productVersionsResp: ProductSearchResult[] = await response.json()
        setProductVersions(productVersionsResp)
        setIsLoading(false)
      }

    
    const { getAccessTokenSilently  } = useAuth0()



    return {
        productVersions,
        isLoading,
        getProductVersions
    }
}