import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ConfirmActionModal } from './ConfirmActionModal'

const ConfirmDeleteModal = ({
    isModalActive,
    isLoading,
    onConfirmDelete,
    onCancel,
}: {
    isModalActive: boolean,
    isLoading: boolean,
    onConfirmDelete: () => void,
    onCancel: () => void

}) => {

    return(
        <ConfirmActionModal 
            title='Are you sure you want to delete?'
            subtitle='Once you delete this item, it will no longer exist.'
            Icon={<FontAwesomeIcon icon={faExclamationTriangle} size={`3x`} />}
            confirmBtnText='Delete'
            isModalActive={isModalActive}
            isLoading={isLoading}
            onCancel={onCancel}
            onConfirmAction={onConfirmDelete} />
    )

}

export { ConfirmDeleteModal }