import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'

export type SearchFilterSelection = {
  appliedFilters: string[],
  hasNoneSelected?: boolean
}

export type SearchFilters = Record<string, SearchFilterSelection>

export const useProductFilters = () => {

    const { getAccessTokenSilently  } = useAuth0()

    const [productFilters, setProductFilters] = useState<SearchFilters>({
        steelworkCode: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        handrail: {
          appliedFilters: [],
          hasNoneSelected: false, 
        },
        productType: {
          appliedFilters: []
        },
        toe: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        flowControlDeviceSize: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        flowControlDeviceType: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        secondaryCasting: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        reinforcing: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        invertLevel: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        openingLevel: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        insideDiameter: {
          appliedFilters: [],
          hasNoneSelected: false,
        },
        pipeMaterial: {
          appliedFilters: [],
          hasNoneSelected: false,
        }
    })

    const emptyAppliedFiltersState = (searchFilters: SearchFilters) => {
        return Object.fromEntries(Object.entries(searchFilters).map(([key, value]) => {
            return [key, {
            ...value,
            appliedFilters: [],
            hasNoneSelected: false, 
            }]
        }))
    }

    const onClearFilters = () => {
        const emptyAppliedProductFilters: SearchFilters = emptyAppliedFiltersState(productFilters)
        setProductFilters(emptyAppliedProductFilters)
    }
  
  
    const onCheckFilter = (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => {
      setProductFilters({
        ...productFilters,
        [facet]: {
          ...productFilters[facet],
          appliedFilters: e.currentTarget.checked ? [...productFilters[facet].appliedFilters, facetValue] : productFilters[facet].appliedFilters.filter(_ => _ !== facetValue)
        }
      })
    }

    const onCheckNoneFilter = (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => {
      setProductFilters({
        ...productFilters,
        [facet]: {
          ...productFilters[facet],
          hasNoneSelected: e.currentTarget.checked
        }
      })
    }

    return {
        productFilters,
        onClearFilters,
        onCheckFilter,
        onCheckNoneFilter
    }
}