import React from 'react'

const BaseModal = ({
    isModalActive,
    modalTitle,
    onCancelModal,
    children,
}:{
    isModalActive: boolean,
    modalTitle: string,
    onCancelModal: (e: React.MouseEvent) => void, 
    children: React.ReactNode
}) => {

    return(
        <div className={`modal ${isModalActive ? `is-active`: ``}`}>
            <div className="modal-background"></div>

            <div className="modal-card box">

                    <div className="columns mb-0 pb-0">
                        <div className="column">
                            <button className="delete is-pulled-right" onClick={onCancelModal} aria-label="close"></button>
                        </div>
                    </div>

                <div className="modal-card-body p-5">
                    <div className="columns pl-0 pr-0">
                        <div className="column">
                            <h1 className="title is-3 mb-3">{modalTitle}</h1>
                        </div>
                    </div>

                    { children }
                </div>
            </div>

        </div>
    )
}

export { BaseModal }