import React from 'react'

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { FlowControlDevice, PipeOpening, ProductSearchResult } from 'SearchScreen'
import { ConfigValueItem, DATE_FORMAT, prettyDisplayValue } from 'constants/Constants'
import moment from 'moment'

const PipeInfoRow = ({
    label, 
    description, 
    insideDiameter, 
    outsideDiameter, 
    opening, 
    openingLevel
}: {
    label: string,
    description?: string, 
    insideDiameter?: number, 
    outsideDiameter?: number, 
    opening?: number,
    openingLevel?: number
}) => (
    <View style={styles.tableRow}> 
        <View style={styles.tableColWidth5}>
            <Text style={styles.tableCell}>{label}</Text> 
        </View> 
        <View style={styles.tableColWidth35}>
            <Text style={styles.tableCell}>{description && prettyDisplayValue(description)}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{insideDiameter ? `${insideDiameter}mm` : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{outsideDiameter ? `${outsideDiameter}mm` : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{opening ? `${opening}mm` : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{openingLevel ? `${openingLevel}mm` : ''}</Text> 
        </View> 
    </View> 
)

const Row_85_15 = ({cell85Value, cell15Value, isRedText = false}: {cell85Value?: string, cell15Value?: string, isRedText?: boolean}) => (
    <View style={styles.tableRow}> 
        <View style={styles.tableColWidth85}>
            <Text style={styles.tableCell}>{cell85Value ? cell85Value : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={[styles.tableCell, isRedText ? styles.redText : {}]}>{cell15Value ? cell15Value : ''}</Text> 
        </View> 
    </View>
)

const Row_45_40_15 = ({cell45Value, cell40Value, cell15Value}: {cell45Value?: string, cell40Value?: string, cell15Value?: string}) => (
    <View style={styles.tableRow}> 
        <View style={styles.tableColWidth45}> 
            <Text style={styles.tableCell}>{cell45Value ? cell45Value : ''} </Text> 
        </View> 
        <View style={styles.tableColWidth40}> 
            <Text style={styles.tableCell}>{cell40Value ? cell40Value : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{cell15Value ? cell15Value : ''}</Text> 
        </View> 
    </View> 
)

const Row_70_15_15 = ({cell70Value, cell15ValueOne, cell15ValueTwo}: {cell70Value?: string, cell15ValueOne?: string, cell15ValueTwo?: string}) => (
    <View style={styles.tableRow}> 
        <View style={styles.tableColWidth70}> 
            <Text style={styles.tableCell}>{cell70Value ? cell70Value : ''} </Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{cell15ValueOne ? cell15ValueOne : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth15}> 
            <Text style={styles.tableCell}>{cell15ValueTwo ? cell15ValueTwo : ''}</Text> 
        </View> 
    </View> 
)

const Row_40_60 = ({cell40Value, cell60Value}:{cell40Value?: string, cell60Value?: string}) => (
    <View style={styles.tableRow}> 
        <View style={styles.tableColWidth40}>
            <Text style={styles.tableCell}>{cell40Value ? cell40Value : ''}</Text> 
        </View> 
        <View style={styles.tableColWidth60}> 
            <Text style={styles.tableCell}>{cell60Value ? cell60Value : ''} </Text> 
        </View> 
    </View> 
)

export interface ChecklistData {
    jobCastingNo?: string,
    pieceWeight?: number,
    headwallName?: string,
    drawingNo?: string,
    jobIssueDate?: string,
    pipeOpenings?: PipeOpening[],
    toeBeamCode?: string,
    steelworkCodes?: string[],
    handrailCode?: string,
    flowControlItems?: FlowControlDevice[],   
    reinforcementWireCode: string 
}

export const productToChecklistData = (product: ProductSearchResult, productType?: ConfigValueItem): ChecklistData => (
    {
    headwallName: product.productType,
    pieceWeight: productType && productType.weight && productType.weight.value,
    drawingNo: product.productCode,
    jobIssueDate: moment.utc().format(DATE_FORMAT),
    pipeOpenings: product.pipeOpenings,
    toeBeamCode: product.toe,
    steelworkCodes: product.steelworkCode,
    handrailCode: product.handrail && product.handrail.toLowerCase().startsWith('k') ? `${product.handrail}-GS-${product.productType}` : product.handrail.toLowerCase().startsWith('p') ? `${product.handrail}-GRP-${product.productType}` : '',
    flowControlItems: product.flowControlDevices,
    reinforcementWireCode: `RW-${product.productType}`
}
)

const buildPipeOpeningDataTable = (pipeOpenings: PipeOpening[]) => {

    const tableData =  ['-1', '-2', '-3', '-4'].map((label, idx) => {

        if (pipeOpenings[idx]) {
            const po = pipeOpenings[idx]

            return <PipeInfoRow 
                label={label} 
                description={po.material}
                insideDiameter={po.insideDiameter}
                outsideDiameter={po.outsideDiameter}
                opening={po.openingSize}
                openingLevel={po.openingLevel}
                key={idx} /> 
        }

        return <PipeInfoRow label={label} key={idx} /> 
    })

    return (
        <>
            <View style={styles.tableRow}> 
                <View style={styles.tableColWidth5}>
                    <Text style={styles.tableCell}>Ref</Text> 
                </View> 
                <View style={styles.tableColWidth35}>
                    <Text style={styles.tableCell}>Opening Description:</Text> 
                </View> 
                <View style={styles.tableColWidth15}> 
                    <Text style={styles.tableCell}>Pipe I/D:</Text> 
                </View> 
                <View style={styles.tableColWidth15}> 
                    <Text style={styles.tableCell}>Pipe O/D:</Text> 
                </View> 
                <View style={styles.tableColWidth15}> 
                    <Text style={styles.tableCell}>Opening:</Text> 
                </View> 
                <View style={styles.tableColWidth15}> 
                    <Text style={styles.tableCell}>Opening Level:</Text> 
                </View> 
            </View> 
            
            {tableData}
        </>
    )
}


const ProductionAndQualityChecklist = ({ checklistData } : { checklistData: ChecklistData }) => (
    <Document>
      <Page style={styles.body} orientation={'landscape'}>
        <Text style={styles.header} fixed>
          {`Production & Quality Control CheckList: Headwall Castings`}
        </Text>
            <View style={{display: 'flex', flexDirection: 'row', border: '1px solid orange', padding: 4, borderRadius: 3}}>            
                <View style={{width: '50%'}}>
                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth70}> 
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>1 - Mould Setup</Text> 
                            </View> 
                            <View style={styles.tableColWidth30}> 
                                <Text style={styles.tableCell}>Mould </Text> 
                                <Text style={styles.tableCell}>Code # </Text> 
                            </View> 
                        </View>

                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}> 
                                <Text style={styles.tableCell}>{`Headwall Name:      ${checklistData.headwallName}`}</Text> 
                            </View> 
                        </View> 

                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth40}>
                                <Text style={styles.tableCell}>Job Casting #: </Text> 
                            </View> 
                            <View style={styles.tableColWidth30}> 
                                <Text style={styles.tableCell}>{`Piece Weight:      ${checklistData.pieceWeight ? `${checklistData.pieceWeight}kg` : ''}`}</Text> 
                            </View> 
                            <View style={styles.tableColWidth30}> 
                                <Text style={styles.tableCell}>Cast on Date: </Text> 
                            </View> 
                        </View> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth40}>
                                <Text style={styles.tableCell}>{`Drawing #:         ${checklistData.drawingNo}`}</Text> 
                            </View> 
                            <View style={styles.tableColWidth60}> 
                                <Text style={styles.tableCell}>{`Job Issue Date:         ${checklistData.jobIssueDate}`}</Text> 
                            </View> 
                        </View> 

                        <Row_40_60
                            cell40Value='Multiple Piece #:'
                            cell60Value='External Back Height: ' />

                        <Row_40_60
                            cell40Value='Concrete Mix Design Code #:'
                            cell60Value='Internal Back Width:                                           (Total if multiple piece headwall)' />


                        {buildPipeOpeningDataTable(checklistData.pipeOpenings)}

                        <Row_85_15 
                            cell85Value='All checklist details to be read and checked in conjunction with the attached production drawing view dimensions / notes-tick box when details are checked' 
                            cell15Value='Mould Operator'
                            isRedText={true} />

                        <Row_85_15 
                            cell85Value='Mould prepared in accordance with setup guidelines (doc no):' />

                        <Row_85_15 
                            cell85Value='Setup demoulding lifting points as bill of materials & to drawing' />

                        <Row_85_15 
                            cell85Value='Setup permanent lifting points as bill of materials & to drawing' />

                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth85}>
                                <Text style={styles.tableCell}>Setup reinforcement wire, spec:</Text> 
                                <Text style={styles.tableCell}>{`Code: #       ${checklistData.reinforcementWireCode}`}</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                                <Text style={styles.tableCell}></Text> 
                            </View> 
                        </View>

                        <Row_85_15 
                            cell85Value='Setup additional reinforcing wire to drawing views / as required during setup:' />

                        <Row_85_15 
                            cell85Value={`Setup toe beam, code: #         ${checklistData.toeBeamCode}`} />

                        <Row_85_15 
                            cell85Value={`Setup grating, code: #          ${checklistData.steelworkCodes.join(`, `)}`} />

                        <Row_85_15 
                            cell85Value={`Setup handrail, code: #         ${checklistData.handrailCode}`} />

                        <Row_85_15 
                            cell85Value={`Setup flow control items:       ${checklistData.flowControlItems.map(_ => `${prettyDisplayValue(_.type)} : ${_.size}mm`).join(`, `)}`} />

                        <Row_85_15 
                            cell85Value='Setup misc. Fixing sockets:' />

                        <Row_85_15 
                            cell85Value='Setup misc. Cast-in items:' />

                        <Row_85_15 
                            cell85Value='Setup dovetail S/S channel, minimum of 3 magnets per dovetail channel length - ensure sealant is applied around edges day before filling and set overnight' />

                        <Row_85_15 
                            cell85Value='Check reinforcing wire tag matches job ticket number' />

                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}>
                                <Text style={{...styles.tableCell, marginBottom: 148}}></Text> 
                            </View> 
                        </View>   
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}>
                                <Text style={styles.tableCell}>Approval to progress to stage 2</Text> 
                            </View> 
                        </View> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}>
                                <Text style={styles.tableCell}>Mould Operator</Text> 
                                <Text style={styles.tableCell}>Enter name: </Text> 
                            </View> 
                        </View>       
                    </View>
                </View>
                <View style={{width: '50%'}}>
                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth85}> 
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>2 - Pre-filling</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                            <Text style={[styles.tableCell, styles.redText]}>Secondary Checker</Text> 
                            </View> 
                        </View>
                        
                        <Row_45_40_15
                            cell45Value='Refer to details in stage 1 and drawing views: '
                            cell40Value='Dimensions correct' />

                        <Row_45_40_15
                            cell40Value='Lifting points correct' />

                        <Row_45_40_15
                            cell40Value='Demoulding lifting points correct' />

                        <Row_45_40_15
                            cell40Value='Reinforcing wire correct' />

                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}> 
                                <Text style={styles.tableCell}>Secondary checker</Text> 
                                <Text style={styles.tableCell}>Enter name:</Text> 
                            </View> 
                        </View> 
                    </View>

                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}> 
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>3 - Setup Feedback Notes</Text>
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                        <View style={styles.tableColWidth100}> 
                                <Text style={{...styles.tableCell, marginBottom: 40}}>Issues raised during mould setup and pre-filling operations, suggestions and requests:</Text> 
                            </View> 
                        </View> 
                    </View>

                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth85}> 
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>4 - Closing</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                                <Text style={[styles.tableCell, styles.redText]}>Mould Operator</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth85}> 
                                <Text style={styles.tableCell}>Close doors, bolts in stop ends & doors</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                                <Text style={styles.tableCell}></Text> 
                            </View> 
                        </View> 
                    </View>

                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                        <View style={styles.tableColWidth85}>
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>5 - Filling</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                                <Text style={[styles.tableCell, styles.redText]}>Filling Operator</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth85}> 
                                <Text style={styles.tableCell}>Mould filled in accordance with filling guidelines (doc no):</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                                <Text style={styles.tableCell}></Text> 
                            </View> 
                        </View> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth100}> 
                                <Text style={styles.tableCell}>Concrete batch</Text> 
                                <Text style={styles.tableCell}>Reference #: </Text> 
                            </View> 
                        </View> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth70}> 
                                <Text style={styles.tableCell}>Filling operator</Text> 
                                <Text style={styles.tableCell}>Enter name: </Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                                <Text style={[styles.tableCell, styles.redText]}>Mould operator </Text> 
                            </View> 
                            <View style={styles.tableColWidth15}> 
                                <Text style={[styles.tableCell, styles.redText]}>Secondary checker</Text> 
                            </View> 
                        </View> 

                        <Row_70_15_15 
                            cell70Value='As required on open face: insert fixing sockets, demoulding lifters & formers ' />

                    </View>

                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth70}>
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>6 - Demould</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                            <Text style={[styles.tableCell, styles.redText]}>Demould Operator</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                            <Text style={[styles.tableCell, styles.redText]}>Secondary Checker</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                        <View style={styles.tableColWidth100}> 
                                <Text style={styles.tableCell}>Demould Operator</Text> 
                                <Text style={styles.tableCell}>Enter name:</Text> 
                                
                            </View> 
                        </View> 
                    </View>

                    <View style={styles.table}> 
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColWidth70}>
                                <Text style={[styles.tableCell, styles.tableCellHeader]}>7 - Product</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                                <Text style={[styles.tableCell, styles.redText]}>Mould Operator</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                            <Text style={[styles.tableCell, styles.redText]}>Secondary Checker</Text> 
                            </View> 
                        </View>

                        <Row_70_15_15 
                            cell70Value='De-moulded in suitable condition, free of damage exceeding allowable limits' />

                        <Row_70_15_15 
                            cell70Value='Product dimensions, openings and cast in sockets match drawing views' />

                        <Row_70_15_15 
                            cell70Value='Edges filed, inserts & bobbins removed, rust marks / misc. blemishes removed ' />
                            
                        <Row_70_15_15 
                            cell70Value='As required fit transportation struts (see drawing views/notes)' />

                        <Row_70_15_15 
                            cell70Value='If above conditions and dimensional checks are met: apply JKH or Althon sticker & print date, weight, opening size & casting # on headwall exterior' />
                        
                        <View style={styles.tableRow}>
                            <View style={styles.tableColWidth70}>
                                <Text style={styles.tableCell}>If condition or dimensions do not meet requirements:</Text> 
                                <Text style={styles.tableCell}>Follow non-conformance report process for product's repair/replacement</Text> 

                            </View> 
                            <View style={styles.tableColWidth15}>

                            </View> 
                            <View style={styles.tableColWidth15}>

                            </View> 
                        </View>                        

                        <View style={styles.tableRow}>
                            <View style={styles.tableColWidth70}>
                                <Text style={styles.tableCell}>Spot check by quality control:</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                                <Text style={styles.tableCell}>Pass</Text> 
                            </View> 
                            <View style={styles.tableColWidth15}>
                                <Text style={styles.tableCell}>Fail</Text>
                            </View> 
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColWidth25}>
                                <Text style={styles.tableCell}>Spot check carried out:</Text> 
                            </View> 
                            <View style={styles.tableColWidth25}>
                                <Text style={styles.tableCell}>Demould</Text> 
                            </View> 
                            <View style={styles.tableColWidth25}>
                                <Text style={styles.tableCell}>Assembly</Text>
                            </View> 
                            <View style={styles.tableColWidth25}>
                                <Text style={styles.tableCell}>Storage Yard</Text>
                            </View> 
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColWidth50}>
                                <Text style={styles.tableCell}>Non-conformance</Text>
                                <Text style={styles.tableCell}>Enter report #:</Text>
                            </View> 
                            <View style={styles.tableColWidth50}>
                                <Text style={styles.tableCell}>Report raised by</Text> 
                                <Text style={styles.tableCell}>Enter name:</Text>
                            </View> 
                        </View>
                    </View>
                </View>
            </View>
      </Page>
    </Document>
  );

const baseTableColStyles = {
    borderStyle: 'solid' as 'solid' | 'dashed' | 'dotted', 
    borderColor: 'grey',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
}
    
  const styles = StyleSheet.create({
    displayFlex: {
        display: 'flex'
    },
    body: {
      paddingTop: 7,
      paddingBottom: 5,
      paddingHorizontal: 5,
    },
    header: {
      fontSize: 10,
      marginBottom: 10,
      textAlign: 'center',
      fontWeight: 'black', 
      textTransform: 'uppercase',
      color: 'orange'
    },
    table: { 
        display: 'flex', 
        width: "auto", 
        borderStyle: 'solid', 
        borderColor: 'grey',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        margin: '5px',
      }, 
      tableRow: { 
        margin: 'auto', 
        flexDirection: 'row'
      }, 
      tableColWidth100: {
        ...baseTableColStyles,
        width: '100%'
      }, 
      tableColWidth85: {
        ...baseTableColStyles,
        width: '85%'
      }, 
       tableColWidth70: {
        ...baseTableColStyles,
        width: '70%'
      }, 
      tableColWidth60: {
        ...baseTableColStyles,
        width: '60%'
      }, 
      tableColWidth50: {
        ...baseTableColStyles,
        width: '50%'
      }, 
      tableColWidth45: {
        ...baseTableColStyles,
        width: '45%'
      },
      tableColWidth40: {
        ...baseTableColStyles,
        width: '40%'
      }, 
      tableColWidth35: {
        ...baseTableColStyles,
        width: '35%'
      }, 
      tableColWidth30: {
        ...baseTableColStyles,
        width: '30%'
      }, 
      tableColWidth25: {
        ...baseTableColStyles,
        width: '25%'
      }, 
      tableColWidth15: {
        ...baseTableColStyles,
        width: '15%'
      }, 
      tableColWidth5: {
        ...baseTableColStyles,
        width: '5%'
      }, 
      tableCell: {
        padding: 3, 
        fontSize: 5,
        textTransform: 'uppercase'
      },
      tableCellHeader: {
         fontWeight: 'black', 
         fontSize: 9, 
         textTransform: 'uppercase'
      },
      redText: {
        color: 'red'
     }
  });

  export { ProductionAndQualityChecklist }