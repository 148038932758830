import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { SearchScreen } from 'pages/search/SearchScreen'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from 'react-router-dom'
import { IndexProductScreen } from 'IndexProductScreen'
import { IndexDrawingScreen } from 'IndexDrawingScreen'
import { NavBar } from 'NavBar'
import { ManageUsersScreen, User } from 'ManageUsersScreen'
import { SettingsScreen } from 'pages/settings/SettingsScreen'
import { ActiveTab, EDITS_REQUESTED, ManageProductsScreen, PENDING_REVIEW } from 'pages/manage/products/ManageProductsScreen'
import { TestPDFScreen } from 'pages/TestPDFScreen'
import { isMerchant } from 'models/User'

import { API_URL, PAID_ADD_ONS } from 'constants/Constants'

// @ts-ignore
import JKHLogo2 from '../public/images/JKH-Logo-transparent-bg.png'

// @ts-ignore
import TeamImage from '../public/images/undraw_team_landing_page.svg'
import { useManagedProductCounts } from 'hooks/useManagedProductCounts'

export const Permissions = {
    canAdd: (user: User) => {
        if (PAID_ADD_ONS.quarantineZone.isEnabled) {
            return user.role === 'ADMIN' && !isMerchant(user)
        }

        return user.role === 'ADMIN' && !isMerchant(user)
    },
    canEdit: (user: User) => {
        if (PAID_ADD_ONS.quarantineZone.isEnabled) {
            return user.role === 'ADMIN' && !isMerchant(user)
        }

        return user.role === 'ADMIN' && !isMerchant(user)
    },
    canDelete: (user: User) => {
        return user.role === 'ADMIN'
    },
    canViewProductHistory: (user: User) => {
        if (PAID_ADD_ONS.quarantineZone.isEnabled) {
            return user.role === 'ADMIN' && !isMerchant(user)
        }

        return false
    },
    canViewManaged: (user: User) => {
        if (PAID_ADD_ONS.quarantineZone.isEnabled) {
            return user.role === 'ADMIN' && !isMerchant(user)
        }

        return false 
    },
    canViewAllManaged: (user: User) => {
        if (PAID_ADD_ONS.quarantineZone.isEnabled) {
            return user.role === 'ADMIN' && !isMerchant(user)
        }

        return false
    },
    canViewManageUsers: (user: User) => {
        return user.role === 'ADMIN' && !isMerchant(user)
    },
    canViewSettings: (user: User) => {
        return user.role === 'ADMIN' && !isMerchant(user)
    },
    canDownloadProductionChecklist: (user: User) => {
        if (PAID_ADD_ONS.manufacturing.isEnabled) {
            return !isMerchant(user)
        }

        return false
    }
}

const AppController = () => {

    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0()
    const [loggedInUser, setLoggedInUser] = useState<User>(undefined)
    const { manageProductCounts, refresh: refreshCounts } = useManagedProductCounts()

    useEffect(() => {

        const getLoggedInUser = async () => {
            const accessToken: string = await getAccessTokenSilently()

            const getUsersResponse: Promise<Response> = fetch(`${API_URL}/users/${encodeURI(user.sub)}`, {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
            })

            const response: Response = await getUsersResponse
            const userResponse: User = await response.json()

            setLoggedInUser(userResponse)
        }

        user && user.sub && getLoggedInUser()
            
    }, [user])

    if (isLoading) return null;

    return (
        <>
        {isAuthenticated
        ?
        <>
        {loggedInUser && 
            <Router>
                <NavBar loggedInUser={loggedInUser} manageProductCounts={manageProductCounts} />
                <Routes>
                    {Permissions.canAdd(loggedInUser) && <Route path="/add/product"  element={<IndexProductScreen loggedInUser={loggedInUser} />} /> }
                    {Permissions.canAdd(loggedInUser) && <Route path="/add/drawing"  element={<IndexDrawingScreen loggedInUser={loggedInUser} />} /> }
                    {Permissions.canViewAllManaged(loggedInUser) && <Route path="/manage/products" element={<ManageProductsScreen loggedInUser={loggedInUser} activeTab={ActiveTab.PENDING_REVIEW} manageProductCounts={manageProductCounts} refreshCounts={refreshCounts} />} /> }
                    {Permissions.canViewAllManaged(loggedInUser) && <Route path="/manage/products/edits" element={<ManageProductsScreen loggedInUser={loggedInUser} activeTab={ActiveTab.EDITS_REQUESTED} manageProductCounts={manageProductCounts} refreshCounts={refreshCounts} />} /> }
                    <Route path="/edit/product/:productCode" element={<IndexProductScreen pageState={Permissions.canEdit(loggedInUser) ? 'edit' : 'view'} loggedInUser={loggedInUser} />} />
                    {!isMerchant(loggedInUser) && <Route path="/edit/drawing/:objectID" element={<IndexDrawingScreen pageState={Permissions.canEdit(loggedInUser) ? 'edit' : 'view'} loggedInUser={loggedInUser} />} /> }
                    {/** Used for linking to drawing from product temporily. */}
                    {!isMerchant(loggedInUser) && <Route path="/view/drawing/:objectID" element={<IndexDrawingScreen pageState={'view'} loggedInUser={loggedInUser} />} /> }
                    {Permissions.canViewManageUsers(loggedInUser) && <Route path="/manage-users" element={<ManageUsersScreen loggedInUser={loggedInUser} />} /> }
                    {Permissions.canViewSettings(loggedInUser) && <Route path="/settings" element={<SettingsScreen />} /> }
                    <Route path="/pdf-test" element={<TestPDFScreen />} />
                    <Route path="/search/:index" element={<SearchScreen loggedInUser={loggedInUser} />} />
                    <Route path="/" element={<Navigate replace to={'/search/products'} />} />
                    <Route path="*" element={<Navigate replace to={'/'} />} />
                </Routes>
            </Router>
        }
        </>
        :
        <section className="hero is-fullheight">
            <div className="hero-body">

                <nav className="navbar is-fixed-top mt-2" style={{background: '#f9f9f9'}}>
                    <div className="container">
                        <div className="navbar-brand">
                            <span className="subtitle is-2">cadly</span>
                        </div>
                    </div>
                </nav>

                <div className="container has-text-centered">
                    <div className="columns is-vcentered">
                        <div className="column is-5">
                            <img src={JKHLogo2} alt="JKH logo." width={'30%'} height={'30%'} />
                            <h1 className="title is-1">👋🏻 Welcome back!</h1>
                            <h2 className="subtitle is-3">Log in to join your team</h2>
                            <button className="button is-link is-rounded is-medium" onClick={() => loginWithRedirect()}>Log In</button>
                        </div>
                        <div className="column is-5 is-offset-1">
                            <img src={TeamImage} alt="An image of a team." />
                        </div>
                    </div>

                </div>
            </div>
        </section>
        }
        </>
    )
}

export { AppController }